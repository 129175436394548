import { isValidEmail } from '../helpers'
import { DeepReadonly } from '../utils'

export interface IDHaver {
  readonly id: string
}

export interface IQuery {
  readonly order: number
  readonly query: string
}

export interface BaseView {
  /**
   * Unix timestamp in ms of the last interaction contained therein.
   */
  readonly endDateTime: number
  /**
   * The id.
   */
  readonly id: string
  readonly queries: readonly IQuery[]
  /**
   * Unix timestamp in ms of the first interaction contained therein.
   */
  readonly startDateTime: number
  /**
   * If applicable, will contain the total amount of interactions contained in
   * this node's children. If not applicable, this value will be zero.
   */
  readonly total: number
}

export interface List extends BaseView {
  readonly children: readonly IView[]
  readonly type: 'list'
}

export const isObject = (o: unknown): o is Record<string, unknown> =>
  typeof o === 'object' && o !== null

// #region Org
export interface Org extends BaseView {
  readonly attachmentCount: number
  readonly children: Relationships
  readonly managedBy: readonly string[]
  readonly messageCount: number
  /**
   * Domain in normal notation e.g. `microsoft.com`.
   */
  readonly name: string
  readonly score?: number
  readonly totalProjects?: number
  readonly closedProjects?: number
  readonly didNotCloseProjects?: number
  readonly type: 'org'
}
export type Orgs = readonly Org[]

export const createEmptyOrg = (domain: string): Org => ({
  attachmentCount: 0,
  children: [],
  closedProjects: 0,
  didNotCloseProjects: 0,
  endDateTime: 0,
  id: domain,
  managedBy: [],
  messageCount: 0,
  name: domain,
  queries: [
    {
      order: 1,
      query: '',
    },
  ],
  score: 0,
  startDateTime: 0,
  total: 0,
  totalProjects: 0,
  type: 'org',
})

export const isOrg = (o: unknown): o is Org =>
  isObject(o) &&
  Array.isArray(o['children']) &&
  o['children'].every(isRelationship) &&
  typeof o['endDateTime'] === 'number' &&
  typeof o['name'] === 'string' &&
  typeof o['startDateTime'] === 'number' &&
  typeof o['total'] === 'number'
// #endregion Org

// #region Relationship

export interface Relationship extends BaseView {
  /**
   * Inwards: External person's email address.
   * Outwards: Keystone person's email address.
   */
  readonly address: string
  /**
   * Interactions pertaining to this relationship.
   */
  readonly children: readonly Convo[] // ???
  /**
   * Inwards: External person's email address.
   * Outwards: `${orgDomain}<->${internalEmailAddress}`
   */
  readonly id: string
  /**
   * Only populated if direction is inwards.
   */
  readonly managedBy: readonly string[]
  /**
   * Only populated if direction is outwards.
   */
  readonly manages: readonly string[]
  /**
   * External person's name.
   */
  readonly name: string
  readonly type: 'relationship'
}

export type Relationships = readonly Relationship[]

export const isRelationship = (o: unknown): o is Relationship =>
  isObject(o) && typeof o['address'] === 'string'

// #endregion Relationship

// #region InteractionsWithPerson

export interface InteractionsWithPerson extends BaseView {
  /**
   * Keystone person's email address when direction is external->internal.
   * External person's email address when direction is internal->external.
   */
  readonly address: string
  /**
   * Conversations.
   */
  readonly children: readonly Convo[]
  /**
   * External person's email address.
   */
  readonly externalAddress: string
  /**
   * Inwards: `${externalEmailAddress}<->${internalEmailAddress}`.
   * Outwards: `${internalEmailAddress}<->${externalEmailAddress}`.
   */
  readonly id: string
  /**
   * Only populated if direction is outwards.
   */
  readonly managedBy: readonly string[]
  /**
   * Only populated if direction is inwards.
   */
  readonly manages: readonly string[]
  readonly name: string
  readonly target: {
    readonly emailAddress: string
    readonly name: string
  }
  readonly type: 'interactionsWithPerson'
}

export const isInteractionsWithPerson = (
  o: unknown,
): o is InteractionsWithPerson => isObject(o) && typeof o['name'] === 'string'

export type InteractionsWithPersonArr = readonly InteractionsWithPerson[]

// #endregion InteractionsWithPerson

// #region Convo

export interface Convo extends BaseView {
  readonly children: readonly never[]
  /**
   * Same as `id`.
   */
  readonly conversationId: string
  /**
   * Same as `conversationId`.
   */
  readonly id: string
  /**
   * The subject of the email conversation.
   */
  readonly subject: string
  readonly type: 'convo'
}

export const isConvo = (o: unknown): o is Convo =>
  isObject(o) &&
  typeof o['subject'] === 'string' &&
  typeof o['conversationId'] === 'string'

export type Convos = readonly Convo[]

// #endregion Convo

export interface MongoOrg {
  /**
   * Domain in reverse notation. Domain in normal notation will be used as the
   * id.
   */
  readonly Name: string
}

export type StageName =
  | 'Complete'
  | 'Did Not Close'
  | 'On Pause'
  | 'Proposal - Uncertain'

export interface Neo4JProject {
  readonly _key: string
  readonly name: string
  readonly projectCode: string
  readonly stageName: StageName
}

export type Neo4JProjects = readonly Neo4JProject[]

export interface MongoProject {
  readonly _id: string
  readonly projectCode: string
}

export interface MongoGraphDBView {
  readonly _id: string
  readonly name: string
  readonly organizations: readonly MongoOrg[]
  readonly projects: readonly MongoProject[]
}

export interface GraphDBView {
  readonly _id: string
  readonly canEdit: boolean
  readonly name: string
  readonly orgs: readonly string[]
  readonly people: readonly string[]
  readonly projects: readonly string[]
}

export type GraphDBViewWithoutID = Omit<GraphDBView, '_id'>

export type GraphDBViewLens =
  | 'inside'
  | 'knowledge'
  | 'network'
  | 'prospects'
  | 'relationships'

export const graphDBViewLenses = [
  'inside',
  'knowledge',
  'network',
  'prospects',
  'relationships',
]

export const DEFAULT_LENS = 'relationships'

export interface Document extends BaseView {
  readonly children: readonly PersonForADoc[]
  /**
   * Concatenation of convoID + all known IDs for the doc, sometimes the same
   * doc gets different IDs when there's a reply / CC_TO / etc. Formatted as
   * following: `orgDomain_$$_>convoId_$_>attachmentId1/_$_/attachmentId2/_$_/...`.
   */
  readonly id: string
  /**
   * Keystone persons' address involved in the conversation containing the
   * doc, won't be displayed, only used to download the attachment.
   */
  readonly keystoneAddresses: readonly string[]
  /**
   * Used only to download the attachment.
   */
  readonly messageIds: readonly string[]
  readonly name: string
  /**
   * Represents the number of people (both external and internal) involved in
   * the document.
   */
  readonly numPeople: number
  readonly size: string
  readonly type: 'document'
}

export type Documents = readonly Document[]

export interface PersonForADoc extends BaseView {
  readonly address: string
  readonly children: readonly never[]
  readonly name: string
  readonly type: 'personForADoc'
}

export type PeopleForADoc = readonly PersonForADoc[]

export type IView =
  | Convo
  | Document
  | InteractionsWithPerson
  | List
  | Org
  | PersonForADoc
  | Relationship

export type IViews = readonly IView[]

export type IViewType = IView['type']

export const IViewTypes: readonly IViewType[] = [
  'convo',
  'document',
  'interactionsWithPerson',
  'list',
  'org',
  'personForADoc',
  'relationship',
]

const noopChildType: Record<GraphDBViewLens, IViewType | null> = {
  inside: null,
  knowledge: null,
  network: null,
  prospects: null,
  relationships: null,
}

export const viewTypeToChildType: Record<
  IViewType,
  Record<GraphDBViewLens, IViewType | null>
> = {
  convo: noopChildType,
  document: noopChildType,
  interactionsWithPerson: {
    ...noopChildType,
    knowledge: 'convo',
    relationships: 'convo',
  },
  list: {
    ...noopChildType,
    knowledge: 'org',
    relationships: 'org',
  },
  org: {
    ...noopChildType,
    knowledge: 'document',
    relationships: 'relationship',
  },
  personForADoc: noopChildType,
  relationship: {
    ...noopChildType,
    relationships: 'interactionsWithPerson',
  },
}

/**
 * Stores the lists of excluded domains and excluded emails.
 */
export interface Exclusions {
  readonly excluded_domains: readonly string[]
  readonly excluded_emails: readonly string[]
}

/**
 * @see {Exclusions}
 */
export const isExclusions = (o: unknown): o is Exclusions => {
  if (typeof o !== 'object' || o === null) {
    return false
  }
  const obj = o as Exclusions

  return (
    Array.isArray(obj['excluded_domains']) &&
    obj['excluded_domains'].every((i) => typeof i === 'string') &&
    Array.isArray(obj['excluded_emails']) &&
    obj['excluded_emails'].every((i) => typeof i === 'string')
  )
}

export type ExclusionScope = 'global' | 'individual'

export interface Exclusion {
  _id: string
  createDate: number
  exclusionGraphKey: string
  exclusionType: IViewType
  scope: ExclusionScope
  /**
   * Auth0 unique identifier.
   */
  user: string
  userEmail: string
  viewLensExclusionLocation: GraphDBViewLens
}

export const isExclusion = (o: unknown): o is Exclusion => {
  if (!isObject(o)) {
    return false
  }
  const {
    _id,
    createDate,
    exclusionGraphKey,
    exclusionType,
    scope,
    user,
    userEmail,
    viewLensExclusionLocation,
  } = o as unknown as Exclusion

  return (
    typeof _id === 'string' &&
    typeof createDate === 'number' &&
    typeof exclusionGraphKey === 'string' &&
    IViewTypes.includes(exclusionType) &&
    // Eslint bugged?
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (scope === 'global' || scope === 'individual') &&
    typeof user === 'string' &&
    isValidEmail(userEmail) &&
    graphDBViewLenses.includes(viewLensExclusionLocation)
  )
}

export type SortMethod = 'last' | 'most'
export enum SortBy {
  DATE = 'DATE',
  INTERACTIONS = 'INTERACTIONS',
  PROJECTS = 'PROJECTS',
}
export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface OrgsSortMethod {
  readonly column: keyof Org
  // readonly column: string
  readonly direction: SortDirection
}

export type SortMapping = DeepReadonly<OrgsSortMethod[]>

export const defaultSortMapping: SortMapping = [
  {
    column: 'endDateTime',
    direction: SortDirection.DESC,
  },
  {
    column: 'closedProjects',
    direction: SortDirection.DESC,
  },
  {
    column: 'messageCount',
    direction: SortDirection.DESC,
  },
]

/**
 * Stores one config from DB.
 */
export interface MetaConfig {
  readonly key: string
  readonly value: string
}
