import React, { ReactElement, memo, useCallback, useMemo } from 'react'

import * as common from '../../../common'
import { IView, emptyFn, emptyArr } from '../../../common'

import moment from 'moment'
import * as rr from 'react-router-dom'

import * as apis from '../../apis'
import {
  selectAllSettings,
  setSetting,
  useDispatch,
  useSelector,
} from '../../redux'

import Row, { Cell } from './Row'

export interface ViewProps {
  isSearching?: boolean
  onAssignManager?: (id: string) => void
  onProjectsRequest?: (orgDomain: string) => void
  onExpand: (nodeID: string) => void
  onRetry?: (nodeID: string) => void
  parentName?: string
  readonly view: IView
}

const View = memo<ViewProps>(
  ({
    isSearching = false,
    onAssignManager,
    onProjectsRequest,
    onExpand,
    onRetry,
    parentName,
    view,
  }): ReactElement | null => {
    const dispatch = useDispatch()
    const { children, id, queries, type } = view
    const { lens } = rr.useParams<{ lens: common.GraphDBViewLens }>()

    // const handleDocumentClick = React.useCallback(() => {
    //   const {id,messageId} = (view as common.Document)

    // }, [view])

    const settings = useSelector(selectAllSettings)

    const exclusions = settings['exclusions'] as unknown as common.Exclusions

    const excludeText = viewToExcludeText(view)

    const handleExcludeOrg = React.useCallback(
      async (domain: string) => {
        try {
          common.graphDBViewLenses.forEach((lensToBeClosed) => {
            dispatch(
              setSetting({
                key: `${lensToBeClosed}/open/${domain}`,
                value: false,
              }),
            )
          })

          dispatch(
            setSetting({
              key: `excluding/${domain}`,
              value: true,
            }),
          )
          await apis.excludeDomain(domain)

          const newExclusions: common.Exclusions = {
            ...exclusions,
            excluded_domains: common.uniq([
              ...exclusions.excluded_domains,
              domain,
            ]),
          }

          dispatch(
            setSetting({
              key: `exclusions`,
              value: newExclusions as unknown as Record<string, unknown[]>,
            }),
          )
        } catch (e) {
          console.log(e)
          dispatch(
            setSetting({
              key: `excluding/${domain}`,
              value: false,
            }),
          )
        }
      },
      [dispatch, exclusions],
    )

    const handleExcludeEmail = React.useCallback(
      async (emailAddress: string) => {
        try {
          common.graphDBViewLenses.forEach((lensToBeClosed) => {
            dispatch(
              setSetting({
                key: `${lensToBeClosed}/open/${emailAddress}`,
                value: false,
              }),
            )
          })
          dispatch(
            setSetting({
              key: `excluding/${emailAddress}`,
              value: true,
            }),
          )
          await apis.excludeEmail(emailAddress)

          const newExclusions: common.Exclusions = {
            ...exclusions,
            excluded_emails: common.uniq([
              ...exclusions.excluded_emails,
              emailAddress,
            ]),
          }

          dispatch(
            setSetting({
              key: `exclusions`,
              value: newExclusions as unknown as Record<string, unknown[]>,
            }),
          )
        } catch (e) {
          console.log(e)
          dispatch(
            setSetting({
              key: `excluding/${emailAddress}`,
              value: false,
            }),
          )
        }
      },
      [dispatch, exclusions],
    )

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const open = Boolean(settings[`${lens!}/open/${id}`])

    const error = settings[`error/${id}`] as string | undefined

    const handleRowExpand = useCallback((): void => {
      if (!open) {
        onExpand(id)
      }
      dispatch(
        setSetting({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          key: `${lens!}/open/${id}`,
          value: !open,
        }),
      )
    }, [open, dispatch, lens, id, onExpand])

    const cells = useMemo((): Cell[] => {
      const { total } = view

      if (type === 'document') {
        const { name, numPeople, size, startDateTime, endDateTime } = view

        let dateString = ''

        const startDateString =
          startDateTime === 0
            ? 'Unknown'
            : moment(startDateTime).format('MM/DD/YYYY')
        const endDateString =
          endDateTime === 0
            ? 'Unknown'
            : moment(endDateTime).format('MM/DD/YYYY')

        if (startDateString === endDateString) {
          dateString = startDateString
        } else {
          dateString = `${startDateString} - ${endDateString}`
        }

        return [
          {
            text: name,
          },
          {
            text: `${Math.round((Number(size) / 1000000) * 10) / 10} mb`,
          },
          {
            text: dateString,
          },
          {
            text: `${numPeople} people`,
          },
          {
            text: '$$__EXPAND',
          },
        ]
      }
      if (type === 'personForADoc') {
        const { address, name } = view

        return [
          {
            text: `${name} (${address})`,
          },
        ]
      }
      if (type === 'convo') {
        const { endDateTime, startDateTime, subject } = view

        const humanDate = getHumanDate(startDateTime, endDateTime)

        return [
          {
            text: subject,
          },
          ...humanDate.map((text) => ({ text })),
          {
            text: `✉️${total}`,
          },
          {
            text: ``,
          },
        ]
      }
      if (type === 'interactionsWithPerson') {
        const {
          address,
          endDateTime,
          id: intID,
          managedBy,
          manages,
          name,
          startDateTime,
        } = view

        const humanDate = getHumanDate(startDateTime, endDateTime)
        const dateCells = humanDate.map((text) => ({ text }))

        const [firstAddress, secondAddress] = intID.split('<->')

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const isInwards = common.isKeystoneEmail(secondAddress!)

        const shouldShowManagerIcon =
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          isInwards && manages.includes(firstAddress!)

        const managedTooltip = managedBy.length
          ? `Managed By: ${managedBy.join(', ')}`
          : undefined

        const managesTooltip = shouldShowManagerIcon
          ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            `Manages ${firstAddress!}`
          : undefined

        return [
          {
            badge: !!managedBy.length && `${managedBy.length} Managers`,
            text: `${name} (${address})${shouldShowManagerIcon ? '👤' : ''}`,
            tooltipTextBadge: managedTooltip || managesTooltip,
          },
          ...dateCells,
          {
            text: `✉️${total}`,
          },
          {
            text: '$$__EXPAND',
          },
        ]
      }
      if (type === 'org') {
        const {
          attachmentCount,
          endDateTime,
          name,
          startDateTime,
          managedBy,
          messageCount,
          closedProjects,
          didNotCloseProjects,
        } = view

        const [subTLDName, TLD] = name.split('.')
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        const reverseDomainNotationName = `${TLD}.${subTLDName}`

        const greyedOutStyleIfApplicable =
          lens === 'knowledge' && attachmentCount === 0
            ? {
                color: 'grey',
                filter: 'grayscale(1)',
              }
            : common.emptyObj

        const humanDate = getHumanDate(startDateTime, endDateTime)
        const dateCells = humanDate.map((text) => ({
          style: greyedOutStyleIfApplicable,
          text,
        }))

        return [
          {
            badge: !!managedBy.length && `${managedBy.length} Managers`,
            style: greyedOutStyleIfApplicable,
            text: reverseDomainNotationName,
            tooltipTextBadge: managedBy.length
              ? `Managed By: ${managedBy.join(', ')}`
              : undefined,
          },
          ...dateCells,
          {
            onClick(): void {
              onProjectsRequest?.(name)
            },
            style: greyedOutStyleIfApplicable,
            text: `💼 ${closedProjects || 0}`,
            tooltipTextBadge: `Projects`,
          },
          {
            onClick(): void {
              onProjectsRequest?.(name)
            },
            style: { filter: 'grayscale(1)' },
            text: `💼❌ ${didNotCloseProjects || 0}`,
            tooltipTextBadge: `Sales that did not close`,
          },
          {
            style: greyedOutStyleIfApplicable,
            text: `✉️${messageCount}`,
            tooltipTextBadge: `Messages`,
          },
          {
            style: greyedOutStyleIfApplicable,
            text: `🗂️${attachmentCount}`,
            tooltipTextBadge: `Attachments`,
          },
          {
            text: '$$__EXPAND',
          },
        ]
      }

      // I dunno why Eslint complains here
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (type === 'relationship') {
        const {
          address,
          endDateTime,
          id: relID,
          managedBy,
          manages,
          name,
          startDateTime,
        } = view

        const humanDate = getHumanDate(startDateTime, endDateTime)

        const isOutwards = relID.includes('<->')

        const [orgDomain] = relID.split('<->')

        // orgDomain will be defined if direction is outwards.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const shouldShowManagerIcon = isOutwards && manages.includes(orgDomain!)

        const managedTooltip = managedBy.length
          ? `Managed By: ${managedBy.join(', ')}`
          : undefined

        const managesTooltip = shouldShowManagerIcon
          ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            `Manages ${orgDomain!}`
          : undefined

        return [
          {
            badge: !!managedBy.length && `${managedBy.length} Managers`,
            text: `${name} (${address})${shouldShowManagerIcon ? '👤' : ''}`,
            tooltipTextBadge: managedTooltip || managesTooltip,
          },
          ...humanDate.map((text) => ({ text })),
          {
            text: `✉️${total}`,
          },
          {
            text: '$$__EXPAND',
          },
        ]
      }

      return []
    }, [lens, onProjectsRequest, type, view])

    if (type === 'document') {
      const { keystoneAddresses } = view

      if (keystoneAddresses.length === 0) {
        throw new ReferenceError(`Unexpected keystoneAddresses.length === 0`)
      }

      // We'll later use the doc id so the backend tries all possible
      // combinations of address+messageId in order to successfully download the
      // doc from Microsoft.

      return (
        <>
          <Row
            cells={cells}
            excludeText={excludeText}
            id={id}
            href={`${apis.BASE_URL}/attached_document/?id=${encodeURIComponent(
              id,
            )}&token=${common.getFrontCacheItem('attachmentsToken') as string}`}
            level={queries.length}
            onAssignManager={onAssignManager}
            onExpand={handleRowExpand}
            open={open}
            retryText={isSearching ? 'See all data' : 'Retry'}
          />

          {open &&
            (children.length > 0 ? (
              children.map((childView): ReactElement | null => (
                <View
                  isSearching={isSearching}
                  key={childView.id}
                  onAssignManager={onAssignManager}
                  onExpand={onExpand}
                  onRetry={onRetry}
                  view={childView}
                />
              ))
            ) : (
              <Row
                cells={emptyArr as readonly Cell[]}
                id={id}
                level={queries.length + 1}
                loading
                loadingCells={2}
                onAssignManager={onAssignManager}
                onExpand={emptyFn}
                onRetry={onRetry}
                open={false}
                retryText={isSearching ? 'See all data' : 'Retry'}
              />
            ))}
        </>
      )
    }
    if (type === 'personForADoc') {
      return (
        <Row
          cells={cells}
          excludeText={excludeText}
          id={id}
          level={queries.length}
          onAssignManager={onAssignManager}
          onExpand={handleRowExpand}
          open={open}
          retryText={isSearching ? 'See all data' : 'Retry'}
        />
      )
    }
    if (type === 'convo') {
      return (
        <Row
          cells={cells}
          excludeText={excludeText}
          id={id}
          level={queries.length}
          onAssignManager={onAssignManager}
          onExpand={handleRowExpand}
          open={open}
          retryText={isSearching ? 'See all data' : 'Retry'}
        />
      )
    }
    if (type === 'interactionsWithPerson') {
      const { address } = view
      return (
        <>
          <Row
            assignText={
              common.isKeystoneEmail(parentName || '')
                ? undefined
                : `Assign ${address} as manager of ${
                    parentName || 'parentName'
                  }`
            }
            cells={cells}
            excludeText={excludeText}
            id={id}
            level={queries.length}
            onAssignManager={onAssignManager}
            onExpand={handleRowExpand}
            open={open}
            retryText={isSearching ? 'See all data' : 'Retry'}
          />

          {open &&
            (children.length > 0 ? (
              children.map((childView): ReactElement | null => (
                <View
                  isSearching={isSearching}
                  key={childView.id}
                  onAssignManager={onAssignManager}
                  onExpand={onExpand}
                  onRetry={onRetry}
                  view={childView}
                />
              ))
            ) : (
              <Row
                cells={emptyArr as readonly Cell[]}
                id={id}
                level={queries.length + 1}
                loading
                loadingCells={4}
                onAssignManager={onAssignManager}
                onExpand={emptyFn}
                onRetry={onRetry}
                open={false}
                retryText={isSearching ? 'See all data' : 'Retry'}
              />
            ))}
        </>
      )
    }
    if (type === 'list') {
      return (
        <>
          {children.map((childView): ReactElement | null => (
            <View
              isSearching={isSearching}
              key={childView.id}
              onAssignManager={onAssignManager}
              onProjectsRequest={onProjectsRequest}
              onExpand={onExpand}
              onRetry={onRetry}
              view={childView}
            />
          ))}
        </>
      )
    }
    if (type === 'org') {
      return (
        <>
          <Row
            excludeText={excludeText}
            cells={cells}
            id={id}
            level={queries.length}
            loading={Boolean(settings[`excluding/${id}`])}
            loadingCells={4}
            onAssignManager={onAssignManager}
            onExclude={handleExcludeOrg}
            onExpand={handleRowExpand}
            open={open}
            retryText={isSearching ? 'See all data' : 'Retry'}
          />

          {open &&
            (children.length > 0 ? (
              children.map((childView): ReactElement | null => (
                <View
                  isSearching={isSearching}
                  key={childView.id}
                  onAssignManager={onAssignManager}
                  onExpand={onExpand}
                  onRetry={onRetry}
                  parentName={id}
                  view={childView}
                />
              ))
            ) : (
              <Row
                cells={emptyArr as readonly Cell[]}
                id={id}
                level={queries.length + 1}
                // No children + error = there's an error
                // No children + no error = it's loading
                loading={!error}
                loadingCells={4}
                onAssignManager={onAssignManager}
                onExpand={common.emptyFn}
                onRetry={onRetry}
                open={false}
                retryText={isSearching ? 'See all data' : 'Retry'}
                text={error}
              />
            ))}
        </>
      )
    }
    // I don't understand why Eslint complains here
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (type === 'relationship') {
      const { address } = view

      return (
        <>
          <Row
            assignText={
              common.isKeystoneEmail(address)
                ? `Assign ${address} as manager of ${parentName || 'org'}`
                : undefined
            }
            cells={cells}
            excludeText={excludeText}
            id={id}
            level={queries.length}
            loading={Boolean(settings[`excluding/${id}`])}
            loadingCells={4}
            onAssignManager={onAssignManager}
            onExclude={handleExcludeEmail}
            onExpand={handleRowExpand}
            open={open}
            retryText={isSearching ? 'See all data' : 'Retry'}
          />

          {open &&
            (children.length > 0 ? (
              children.map((childView): ReactElement | null => (
                <View
                  isSearching={isSearching}
                  key={childView.id}
                  onAssignManager={onAssignManager}
                  onExpand={onExpand}
                  onRetry={onRetry}
                  parentName={id}
                  view={childView}
                />
              ))
            ) : (
              <Row
                cells={emptyArr as readonly Cell[]}
                id={id}
                level={queries.length + 1}
                // No children + error = there's an error
                // No children + no error = it's loading
                loading={!error}
                loadingCells={4}
                onAssignManager={onAssignManager}
                onExpand={emptyFn}
                onRetry={onRetry}
                open={false}
                retryText={isSearching ? 'See all data' : 'Retry'}
                text={error}
              />
            ))}
        </>
      )
    }
    throw new TypeError(`Unknown type of entity: ${String(type)}`)
  },
)

export default View

const viewToExcludeText = (node: IView): string => {
  if (node.type === 'convo') {
    return `Exclude this conversation from results`
  } else if (node.type === 'document') {
    return `Exclude ${node.name} from results`
  } else if (node.type === 'personForADoc') {
    return `Exclude ${node.name} from results`
  } else if (node.type === 'interactionsWithPerson') {
    return `Exclude conversations between ${node.target.emailAddress} and ${node.address} from results`
  } else if (node.type === 'list') {
    return ''
  } else if (node.type === 'org') {
    return `Exclude all interactions for ${node.name}`
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (node.type === 'relationship') {
    return `Exclude ${node.address} from results`
  }
  throw new TypeError(`Wrong node type: ${common.stringify(node)}`)
}

// Search with tickbox ala gmail

// user level exclusions (later)

// settings page, collections used to describe an integration data for a person
// and app in general.

// - Relevant to me and relevant to app (e.g. last sync for someone) and to me
//   (e.g. excluded)

// Admin settings first sees everyone's settings. First./

// Click on azure integration see last update for a certain person (Azure data)

// https://s3.us-west-2.amazonaws.com/secure.notion-static.com/1f272868-7524-4a08-8afe-59f2b7d839d8/Untitled.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220817%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220817T191959Z&X-Amz-Expires=86400&X-Amz-Signature=0fec888e286baf674911da0ac5fb0cbf52a1ddbbe37416d62cd1b59bd2c31d6d&X-Amz-SignedHeaders=host&response-content-disposition=file

//  Document links how?

// Filter out too-small stuff, rangebox for size/dates
// Above the search, sort by numOfEmails/etc
// Icons for each type of entity
// Favicon, fallback to generic icon
// For documents, match type, e.g. spreadsheet icon

// Annotation: video as inspiration.
// E.g. annotate a person with a project, e.g. assign project.

// (way later) filter by person

// Why last interaction times differ between org and relationships
// Maybe one of the levels doesn't have sentfrom/cc/sentto

// Go from convo to knowledge

// In the future:
// Flip relationship, go from keystone to external

// invalid date -> unknown

// 1. login
// 2. dates
// 3. search
// 4. link from relationship to knowledge

const getHumanDate = (
  startDateTime: number,
  endDateTime: number,
): readonly string[] => {
  const startUnknown = startDateTime === 0
  const endUnknown = endDateTime === 0

  if (startUnknown && endUnknown) {
    return ['Started: Unknown', 'Latest: Unknown']
  }
  const startDate = moment(startDateTime)
  const endDate = moment(endDateTime)

  if (startUnknown) {
    return ['Started: Unknown', `Latest: ${endDate.fromNow()}`]
  }
  if (endUnknown) {
    return [`Started: ${startDate.fromNow(true)}`, `Latest: Unknown`]
  }

  const dur = moment.duration(endDate.diff(startDate))
  const humanizedDur = dur.humanize()

  return [humanizedDur, `Latest: ${endDate.fromNow()}`]
}
