import React from 'react'
import { styled } from '@mui/material/styles'

import * as common from '../../../../common'

import Button, { ButtonProps } from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import TextField from '@mui/material/TextField'
import * as mIcon from '@mui/icons-material'

import Skeleton from '../../../comps/Skeleton'
import Pad from '../../../comps/Pad'

import './index.scss'

const CustomButton = styled(Button)<ButtonProps>({
  padding: '11px',
})

interface AdvancedOptionsProps {
  readonly getOnChangeSortMapping: (
    param: keyof common.Org,
  ) => React.MouseEventHandler<HTMLButtonElement>
  readonly sortMapping: common.SortMapping
  readonly filtersState: common.ReadonlyRecord<string, boolean>
  readonly getToggleFilter: (
    param: string,
  ) => React.ChangeEventHandler<HTMLInputElement>
  readonly hideDirectionControl: boolean
  readonly isLoading: boolean
  searchInputRef: React.Ref<HTMLInputElement | null>
  readonly handleQueryChange: () => void
  readonly handleDirectionChange: () => void
  readonly selectedDirection: string
}

// eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
const AdvancedOptions = ({
  hideDirectionControl,
  isLoading,
  sortMapping,
  getOnChangeSortMapping,
  filtersState,
  getToggleFilter,
  searchInputRef,
  handleQueryChange,
  handleDirectionChange,
  selectedDirection,
}: AdvancedOptionsProps): React.ReactElement => {
  const filtersOrder = [
    'more_than_one_message',
    'only_clients',
    'has_attachments',
  ]
  return (
    <Skeleton
      loading={isLoading}
      variant="rectangular"
      width="100%"
    >
      <TextField
        fullWidth
        inputRef={searchInputRef}
        onChange={handleQueryChange}
        placeholder="Search"
        sx={{
          backgroundColor: 'white',
          position: 'sticky',
          top: 64,
        }}
      />
      <Pad y={8} />
      <div className="control-container">
        <span className="control-title">Sort by:</span>
        <Pad x={8} />
        <ButtonGroup
          color="primary"
          disabled={isLoading}
        >
          {sortMapping.map((e: common.OrgsSortMethod) => {
            const buttonText = {
              closedProjects: 'Total Closed Projects',
              endDateTime: 'Latest Interaction',
              messageCount: 'Message Count',
            }[e.column as string]
            const Icon = {
              [common.SortDirection.ASC]: mIcon.ArrowUpwardOutlined,
              [common.SortDirection.DESC]: mIcon.ArrowDownwardOutlined,
            }[e.direction]
            return (
              <CustomButton
                key={common.stringify(e)}
                onClick={getOnChangeSortMapping(e.column)}
                className="sort-control__button"
              >
                {buttonText}
                <Icon />
              </CustomButton>
            )
          })}
        </ButtonGroup>
      </div>
      <Pad y={8} />
      <div
        className={
          hideDirectionControl
            ? 'control-container-hidden'
            : 'control-container'
        }
      >
        <span className="control-title">Organize by:</span>
        <Pad x={8} />
        <ToggleButtonGroup
          color="primary"
          exclusive
          onChange={handleDirectionChange}
          value={selectedDirection}
        >
          <ToggleButton value="inwards">External {'->'} Internal</ToggleButton>
          <ToggleButton value="outwards">Internal {'->'} External</ToggleButton>
        </ToggleButtonGroup>
      </div>
      <Pad y={8} />
      <div className="control-container">
        <span className="control-title">Filters:</span>
        <Pad x={8} />
        {filtersOrder.map((filter) => {
          const label = {
            has_attachments: 'Has Attachments',
            more_than_one_message: 'More than one message',
            only_clients: 'Only clients',
          }[filter]
          return (
            <FormControlLabel
              key={filter}
              label={label}
              control={
                <Checkbox
                  checked={filtersState[filter]}
                  inputProps={{
                    'aria-label': label,
                  }}
                  disabled={isLoading}
                  onChange={getToggleFilter(filter)}
                />
              }
            />
          )
        })}
      </div>
    </Skeleton>
  )
}
export default React.memo<AdvancedOptionsProps>(AdvancedOptions)
