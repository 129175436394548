/**
 * Helpers, unlike utils, are business-logic utils.
 */
import * as types from '../types'

export const findNodeFromRoot = (
  root: types.IView,
  id: string,
): types.IView | null | undefined => {
  if (root.id === id) {
    console.log(`findNodeFromRoot() -> returning root itself`)
    return root
  }

  const maybeNode = root.children.find((childNode) => childNode.id === id)

  if (maybeNode) {
    return maybeNode
  }

  const maybeNodes = root.children.map((childNode) =>
    findNodeFromRoot(childNode, id),
  )

  const finallyMaybeNode = maybeNodes.find((childNode) => childNode !== null)

  return finallyMaybeNode || null
}

export const isValidDomain = (domain: unknown): boolean => {
  if (typeof domain !== 'string') {
    return false
  }
  const bits = domain.split('.')

  if (bits.length < 2) {
    return false
  }

  for (const bit of bits) {
    if (bit.includes('@')) {
      return false
    }
  }

  for (const bit of bits) {
    const chars = bit.toLocaleLowerCase().split('')

    const everyCharIsAlphabetical = chars.every((char) =>
      '1234567890abcdefghijklmnopqrstuvwxyz-'.split('').includes(char),
    )
    if (!everyCharIsAlphabetical) {
      return false
    }
  }

  return bits.every((bit) => bit.length > 0)
}

export const isValidEmail = (emailAddress: unknown): boolean => {
  if (typeof emailAddress !== 'string') {
    return false
  }
  const bits = emailAddress.split('@')

  if (bits.length !== 2) {
    return false
  }
  const [identifier, domain] = bits

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return identifier!.length > 0 && isValidDomain(domain!)
}

/**
 * Certain places have domains in reverse notation, certain other places have
 * them in normal notation, let it be handled everywhere in normal notation.
 */
export const reverseToDomain = (reverseDomain: string): string => {
  if (!isValidDomain(reverseDomain)) {
    throw new TypeError(`Not a valid reverse domain: ${reverseDomain}`)
  }
  const bits = reverseDomain.split('.')

  return bits.reverse().join('.')
}

/**
 * Transforms a domain name (e.g. `microsoft.com`) into its reverse notation
 * version (`com.microsoft`), domain data should be stored in normal notation
 * but can and will be displayed in reverse domain notation.
 */
export const domainToReverse = (domain: string): string => {
  if (!isValidDomain(domain)) {
    throw new TypeError(`Not a valid domain: ${domain}`)
  }
  const bits = domain.split('.')

  return bits.reverse().join('.')
}

/**
 * Data is not separated by sub-domain, only domain name at the most, this
 * removes sub-domains for correct handling / matching of data.
 */
export const removeSubDomains = (domain: string): string => {
  const reverse = domainToReverse(domain)

  const [TLD, name] = reverse.split('.')

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return `${name!}.${TLD!}`
}

/**
 * Please keep this array with at least one item.
 */
export const defaultExcludedDomains = ['keystonestrategy.com', 'keystone.ai']

/**
 * Please keep this array with at least one item.
 */
export const defaultExcludedEmails = ['no-reply@github.com']

export const isKeystoneEmail = (emailAddress: string): boolean => {
  const [, domain] = emailAddress.split('@')
  // Account for xxx.keystonestrategy.com
  return (
    !!domain?.toLowerCase().endsWith('keystonestrategy.com') ||
    !!domain?.toLowerCase().endsWith('keystone.ai')
  )
}

export const ITEMS_PER_PAGE = process.env['FAKE_DATA'] === 'true' ? 5 : 50

export const INCLUDED_PROJECT_TYPES = [
  'Bill through',
  'Collaborating party',
  'P.E Invested',
  'Paying/End client',
  'Account',
]
