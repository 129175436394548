import React from 'react'

export interface PadProps {
  x?: number
  y?: number
}

export default React.memo<PadProps>(function Pad({ x, y }) {
  const style = React.useMemo(
    (): React.CSSProperties => ({
      height: y,
      width: x,
    }),
    [x, y],
  )

  return <div style={style} />
})
