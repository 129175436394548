import type { ReportHandler } from 'web-vitals'

const reportWebVitals = (onPerfEntry?: ReportHandler): void => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals')
      // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
      .then(({ getCLS, getFID, getFCP, getLCP, getTTFB }): void => {
        getCLS(onPerfEntry)
        getFID(onPerfEntry)
        getFCP(onPerfEntry)
        getLCP(onPerfEntry)
        getTTFB(onPerfEntry)
      })
      .catch((e): void => {
        // eslint-disable-next-line no-console
        console.log(`reportWebVitals -> `, e)
      })
  }
}

export default reportWebVitals
