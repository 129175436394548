/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { ReactElement, memo } from 'react'

import * as common from '../../../common'

import * as mui from '@mui/material'
import * as muIcon from '@mui/icons-material'
import {
  ArrowForwardIos as ArrowWhenClosed,
  KeyboardArrowDown as ArrowWhenOpen,
  Search as IconWhenClosed,
  UnfoldLess as IconWhenOpen,
} from '@mui/icons-material'

import * as redux from '../../redux'
import * as utils from '../../utils'
import Pad from '../Pad'
import Skeleton from '../Skeleton'
import gStyles from '../../global.module.css'

import styles from './Row.module.css'

export interface Cell {
  readonly badge?: common.Maybe<string>
  readonly onClick?: () => void
  readonly style?: React.CSSProperties
  readonly text: string
  readonly tooltipTextBadge?: string
}

interface RowProps {
  alt?: string
  assignText?: string
  cells?: readonly Cell[]
  excludeText?: string
  readonly id?: string
  readonly href?: string
  level: number
  /**
   * Will render placeholder cells.
   */
  readonly loading?: boolean
  /**
   * How many placeholder cells will be rendered.
   */
  readonly loadingCells?: number
  onAssignManager?: (id: string) => void
  onClick?: (id: string) => void
  /**
   * If provided, an exclude button will be rendered and this function will be
   * called with the id of the row when that button is pressed.
   */
  onExclude?: (id: string) => void
  onExpand?: (id: string) => void
  onRetry?: (id: string) => void
  open?: boolean
  retryText?: string
  text?: string
}

const Row = memo<RowProps>(
  ({
    alt,
    assignText,
    cells = common.emptyArr as readonly Cell[],
    excludeText = 'Exclude',
    id,
    href,
    level,
    loading,
    loadingCells,
    onAssignManager,
    onClick,
    onExclude,
    onExpand,
    onRetry,
    open,
    retryText = 'Retry',
    text,
  }): ReactElement => {
    // One extra placeholder cell that will be hidden, in place of the loupe.
    const cellsToRender = loading
      ? LOADING_CELLS.slice(0, (loadingCells || 0) + 1)
      : cells
    const numCells = Number(cellsToRender.length)
    const controlsSpace = 2.5
    const cellWidth = (1 / (numCells - 1)) * (100 - controlsSpace)

    const shouldHideLevel3 = redux.useSelector(
      redux.selectSetting('shouldHideLevel3'),
    ) as unknown as boolean
    const handleClick = React.useCallback(() => {
      id && onClick?.(id)
    }, [id, onClick])

    const handleExpand = React.useCallback(() => {
      id && onExpand?.(id)
    }, [id, onExpand])

    const handleRetry = React.useCallback(() => {
      id && onRetry?.(id)
    }, [id, onRetry])

    const [{ anchorPos, open: ctxMenuOpen }, setCtxMenu] = React.useState({
      anchorPos: {
        left: 0,
        top: 0,
      },
      open: false,
    })
    const handleRightClick = React.useCallback(
      // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
      (e: utils.CtxMenuEvent) => {
        e.preventDefault()

        setCtxMenu({
          anchorPos: {
            left: e.pageX,
            top: e.pageY,
          },
          open: true,
        })
      },
      [],
    )
    const handleCtxClose = React.useCallback(() => {
      setCtxMenu({
        anchorPos: {
          left: 0,
          top: 0,
        },
        open: false,
      })
    }, [])

    const handleAssignClick = React.useCallback(() => {
      id && onAssignManager?.(id)
      handleCtxClose()
    }, [handleCtxClose, id, onAssignManager])

    const handleExcludeClick = React.useCallback(() => {
      id && onExclude?.(id)
      handleCtxClose()
    }, [handleCtxClose, id, onExclude])

    const rootStyle = React.useMemo(
      () => ({
        backgroundColor: levelToColor[level],
        borderBottom: `1px solid ${levelToColor[level + 1] || 'black'}`,
      }),
      [level],
    )

    return (
      <>
        <mui.Menu
          anchorReference="anchorPosition"
          anchorPosition={anchorPos}
          onClose={handleCtxClose}
          open={ctxMenuOpen}
        >
          {assignText && (
            <mui.MenuItem onClick={handleAssignClick}>
              <mui.ListItemIcon>
                <muIcon.Hail fontSize="small" />
              </mui.ListItemIcon>

              <mui.ListItemText>{assignText}</mui.ListItemText>
            </mui.MenuItem>
          )}

          <mui.MenuItem onClick={handleExcludeClick}>
            <mui.ListItemIcon>
              <muIcon.Block fontSize="small" />
            </mui.ListItemIcon>

            <mui.ListItemText>{excludeText}</mui.ListItemText>
          </mui.MenuItem>

          <mui.Divider />

          <mui.MenuItem disabled>
            <mui.ListItemIcon>
              <muIcon.Drafts fontSize="small" />
            </mui.ListItemIcon>

            <mui.ListItemText>See conversations</mui.ListItemText>
          </mui.MenuItem>
        </mui.Menu>

        <div
          className={
            !!onRetry && !loading ? gStyles['column-center'] : styles['row']
          }
          onClick={handleClick}
          onContextMenu={handleRightClick}
          style={rootStyle}
          title={alt}
        >
          {!!text && !loading && (
            <mui.Typography
              align="center"
              variant="subtitle1"
              width="100%"
            >
              {text}
            </mui.Typography>
          )}
          {!!onRetry && !loading && (
            <mui.Button onClick={handleRetry}>{retryText}</mui.Button>
          )}
          {cellsToRender.map(
            (
              {
                badge,
                onClick: cellOnClick,
                style,
                text: cellText,
                tooltipTextBadge,
              },
              i,
              { length },
            ) => (
              <CellRenderer
                badge={badge}
                cellText={cellText}
                cellWidth={cellWidth}
                tooltipTextBadge={tooltipTextBadge}
                controlsSpace={controlsSpace}
                handleExpand={handleExpand}
                href={href}
                i={i}
                key={id + String(i)}
                length={length}
                loading={loading}
                onClick={cellOnClick}
                open={open}
                renderGlassIcon={level === 1}
                style={style}
                level={level}
                shouldHideLevel3={shouldHideLevel3}
              />
            ),
          )}
        </div>
      </>
    )
  },
)

interface CellRendererProps {
  badge?: common.Maybe<string>
  cellText: string
  cellWidth: number
  controlsSpace: number
  handleExpand: () => void
  href?: string
  i: number
  length: number
  loading: boolean | undefined
  onClick: (() => void) | undefined
  open: boolean | undefined
  renderGlassIcon?: boolean | undefined
  style?: React.CSSProperties
  tooltipTextBadge?: string
  level: number
  shouldHideLevel3: boolean
}

const CellRenderer = React.memo<CellRendererProps>(
  ({
    badge,
    cellText,
    cellWidth,
    controlsSpace,
    handleExpand,
    href,
    i,
    length,
    loading,
    onClick,
    open,
    renderGlassIcon,
    tooltipTextBadge = '',
    style,
    level,
    shouldHideLevel3 = false,
  }) => {
    const ArrowIcon = open ? ArrowWhenOpen : ArrowWhenClosed
    const GlassIcon = open ? IconWhenOpen : IconWhenClosed

    const IconToRender = renderGlassIcon ? GlassIcon : ArrowIcon

    const rootStyle = React.useMemo(
      () => ({
        width: i === length - 1 ? `${controlsSpace}%` : `${cellWidth}%`,
      }),
      [cellWidth, controlsSpace, i, length],
    )

    return (
      <mui.Tooltip title={tooltipTextBadge}>
        <div
          className={
            onClick || cellText.startsWith('$$__EXPAND')
              ? styles['cell-clickable']
              : styles['cell']
          }
          onClick={onClick}
          style={rootStyle}
        >
          {cellText.startsWith('$$__EXPAND') ? (
            shouldHideLevel3 && level === 3 ? null : (
              <IconToRender
                color="primary"
                onClick={handleExpand}
              />
            )
          ) : (
            <Skeleton
              animation="wave"
              hide={i === length - 1}
              loading={loading}
              variant="text"
            >
              {i === 0 && href ? (
                <a
                  href={href}
                  rel="noreferrer"
                  style={style}
                  target="__blank"
                >
                  {cellText}
                </a>
              ) : (
                <span style={style}>{cellText}</span>
              )}

              <Pad x={4} />

              {badge && (
                <mui.Box className={styles['badge']}>
                  <span>{badge}</span>
                </mui.Box>
              )}
            </Skeleton>
          )}
        </div>
      </mui.Tooltip>
    )
  },
)

export default Row

const levelToColor: Record<number, string> = {
  1: 'rgb(247, 247, 247)',
  2: 'rgb(238, 238, 238)',
  3: 'rgb(226, 226, 226)',
  4: 'rgb(211, 211, 211)',
}

// exlucde button to the right, only show on hover, persist to mongo

const LOADING_CELLS: readonly Cell[] = new Array<Cell>(10).fill({
  text: 'Lorem ipsum dolor',
})
