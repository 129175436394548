import * as react from 'react'

import * as mui from '@mui/material'

type OrigProps = react.ComponentPropsWithoutRef<typeof mui.Skeleton>

export interface SkeletonProps {
  animation?: OrigProps['animation']
  children: Required<OrigProps>['children']
  height?: OrigProps['height']
  /**
   * Renders a null in place when `loading` is true.
   */
  hide?: boolean
  /**
   * If false, will directly render the children.
   */
  loading?: boolean
  /**
   * If true, children will not be rendered at all when `loading` is true.
   */
  replace?: boolean
  variant?: OrigProps['variant']
  width?: OrigProps['width']
}

export default react.memo<SkeletonProps>(
  ({
    animation,
    children,
    height,
    hide,
    loading,
    replace,
    variant,
    width,
  }): react.ReactElement | null => {
    if (loading) {
      if (hide) {
        return null
      }
      return (
        <mui.Skeleton
          animation={animation}
          height={height}
          variant={variant}
          width={width}
        >
          {replace ? null : children}
        </mui.Skeleton>
      )
    }
    return children as react.ReactElement
  },
)
