import { DeepReadonly } from '../../common'

import { AnyAction, Store, configureStore } from '@reduxjs/toolkit'
import { ReactElement, memo } from 'react'
import { Provider, useSelector as _useSelector } from 'react-redux'

import { test } from './test'

const reducers = {
  test,
}

export type State = DeepReadonly<{
  [K in keyof typeof reducers]: ReturnType<typeof reducers[K]>
}>

// eslint-disable-next-line @typescript-eslint/init-declarations
let currStore: Store<State>

export const getStore = (): Store<State> => currStore

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const initStore = () => {
  const store = configureStore({
    reducer: reducers,
  })

  currStore = store

  return memo<{
    children: ReactElement
    loading?: ReactElement
  }>(({ children }) => <Provider store={store}>{children}</Provider>)
}

export const useSelector = <TSelected,>(
  selector: (state: State) => TSelected,
): TSelected => _useSelector<State, TSelected>(selector)

/**
 * Faster way to dispatch. Can be used as long as store doesn't change on the
 * fly (which doesn't in this app or 99% of other apps).
 */
export const dispatch = (action: DeepReadonly<AnyAction>): void => {
  getStore().dispatch(action)
}
