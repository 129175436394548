// https://reactrouter.com/docs/en/v6/getting-started/overview
import { ReactElement, StrictMode, memo } from 'react'

import * as mui from '@mui/material'
import ReactDOM from 'react-dom/client'
import { Auth0Provider } from '@auth0/auth0-react'
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom'
// Fonts used by Material UI
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import Dashboard from './routes/Dashboard'
import GraphDBView from './routes/GraphDBView'
import reportWebVitals from './reportWebVitals'
import { initStore } from './redux'
import './index.css'

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(document.getElementById('root')!)

const ReduxWrapper = initStore()

const AuthWrapper = memo<{ children: ReactElement }>(
  ({ children }): ReactElement => {
    const shouldDisableAuth =
      process.env['NODE_ENV'] === 'development' ||
      process.env['NODE_ENV'] === 'test'
    const shouldEnableAuth = !shouldDisableAuth

    if (shouldEnableAuth) {
      console.log(`Enabling auth, NODE_ENV=${process.env['NODE_ENV']}`)

      return (
        <Auth0Provider
          audience="https://test-graph-backend.keystonestrategy.io"
          clientId="gfgtviA7DOds7REXd9tOTIFMCjI4KKKs"
          domain="keystonelabs.auth0.com"
          redirectUri={window.location.origin}
        >
          {children}
        </Auth0Provider>
      )
    }

    console.warn(
      `Will not enable auth, process.env.NODE_ENV=${process.env['NODE_ENV']}`,
    )

    return children
  },
)

const graphDBView = <GraphDBView />

root.render(
  <StrictMode>
    <mui.CssBaseline />

    <AuthWrapper>
      <ReduxWrapper>
        <BrowserRouter>
          <Routes>
            <Route
              element={<Dashboard />}
              path="/"
            >
              <Route
                index
                element={
                  <Navigate
                    replace
                    to="/views/new/relationships"
                  />
                }
              />

              <Route
                element={graphDBView}
                path="/views/new/:lens"
              ></Route>

              <Route
                element={graphDBView}
                path="/views/:id/:lens"
              ></Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </ReduxWrapper>
    </AuthWrapper>
  </StrictMode>,
)

/*
 * If you want to start measuring performance in your app, pass a function to
 * log results (for example: reportWebVitals(console.log)) or send to an
 * analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals()
