import React from 'react'

import * as common from '../../../common'

import * as apis from '../../apis'
import * as redux from '../../redux'

export const useFetchMetaConfig = (
  isAuthenticating: boolean,
  setLoadingMetaConfig: React.Dispatch<React.SetStateAction<boolean>>,
): void => {
  React.useEffect(() => {
    if (isAuthenticating) {
      return
    }
    void (async (): Promise<void> => {
      try {
        const tabsToHideKey = 'tabsToHide'
        const { value: tabsToHideFetched } = await apis.fetchMetaConfigByKey(
          tabsToHideKey,
        )
        redux.dispatch(
          redux.setSetting({
            key: tabsToHideKey,
            value: tabsToHideFetched.split(','),
          }),
        )

        const shouldHideLevel3Key = 'shouldHideLevel3'
        const { value: shouldHideLevel3Fetched } =
          await apis.fetchMetaConfigByKey(shouldHideLevel3Key)
        redux.dispatch(
          redux.setSetting({
            key: shouldHideLevel3Key,
            value: Boolean(shouldHideLevel3Fetched),
          }),
        )

        setLoadingMetaConfig(false)
      } catch (e) {
        const err = common.processErr(e)
        console.log(`Could not fetch meta config: ${err}`)
        console.log(e)
      }
    })()
  }, [isAuthenticating, setLoadingMetaConfig])
}
