/* eslint-disable sort-keys */
/**
 * https://github.com/mui/material-ui/tree/v5.8.4/docs/data/material/getting-started/templates/dashboard
 */
import React from 'react'

import * as common from '../../../common'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import moment from 'moment'
import qs from 'qs'
import { ThemeProvider, createTheme, styled } from '@mui/material/styles'
import { ChevronLeft, Menu } from '@mui/icons-material'
import { Divider, Drawer as MuiDrawer, List, SxProps } from '@mui/material'
import { Theme } from '@mui/system'
import { useAuth0 } from '@auth0/auth0-react'
import { Outlet } from 'react-router-dom'

import * as apis from '../../apis'
import * as redux from '../../redux'
import {
  SelectSettingParams,
  makeSelectSetting,
  setSetting,
  useSelector,
} from '../../redux/'
import { useIsMounted } from '../../utils'

import DrawerItems from './DrawerItems'
import { useFetchMetaConfig } from './hooks'

const drawerWidth = 240

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const mdTheme = createTheme()

const DashboardContent = React.memo<{ loading?: boolean }>(
  ({ loading = false }): React.ReactElement => {
    const [open, setOpen] = React.useState(true)
    const toggleDrawer = (): void => {
      setOpen(!open)
    }
    const handleOpenRequest = React.useCallback(() => {
      setOpen(true)
    }, [])

    const lastSyncDate = redux.useSelector(redux.selectSetting('lastSync'))

    const drawerBtnStyle = React.useMemo(
      (): SxProps => ({
        marginRight: '36px',
        ...(open && { display: 'none' }),
      }),
      [open],
    )

    const drawerToolbarStyle = React.useMemo(
      (): SxProps => ({
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        px: [1],
      }),
      [],
    )

    const mainBoxStyle = React.useMemo(
      (): SxProps<Theme> => ({
        backgroundColor: (theme) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          theme.palette.mode === 'light'
            ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              theme.palette['grey'][100]
            : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              theme.palette['grey'][900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }),
      [],
    )

    const selectSetting = React.useMemo(() => makeSelectSetting(), [])

    const selectSettingArgs = React.useMemo(
      (): SelectSettingParams => ({
        key: 'foo',
      }),
      [],
    )

    const selectedSetting = useSelector((state) =>
      selectSetting(state, selectSettingArgs),
    )

    return (
      <ThemeProvider theme={mdTheme}>
        <Box sx={styles.root}>
          <AppBar
            open={open}
            position="absolute"
          >
            <Toolbar sx={styles.toolbar}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={drawerBtnStyle}
              >
                <Menu />
              </IconButton>

              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={loading ? styles.titleHidden : styles.title}
              >
                Keystone Graph last sync:
                {moment(lastSyncDate as number).format('MM/DD/YYYY')}
                {JSON.stringify(selectedSetting)}
              </Typography>
            </Toolbar>
          </AppBar>

          <Drawer
            variant="permanent"
            open={open}
          >
            <Toolbar sx={drawerToolbarStyle}>
              <IconButton onClick={toggleDrawer}>
                <ChevronLeft />
              </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">
              <DrawerItems
                loading={loading}
                onOpenRequest={handleOpenRequest}
                open={open}
              />
            </List>
          </Drawer>

          <Box
            component="main"
            sx={mainBoxStyle}
          >
            <Toolbar />

            <Outlet context={loading} />
          </Box>
        </Box>
      </ThemeProvider>
    )
  },
)

const styles = {
  content: {
    mt: 4,
    mb: 4,
  },
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  titleHidden: {
    flexGrow: 1,
    opacity: 0,
  },
  toolbar: {
    pr: '24px', // keep right padding when drawer closed
  },
} as const

const Dashboard = (): React.ReactElement => {
  const isMounted = useIsMounted()
  const {
    getAccessTokenSilently,
    isLoading: isLoadingAuth0,
    loginWithPopup,
    user,
  } = useAuth0()
  const [loadingLastSync, setLoadingLastSync] = React.useState(true)
  const [loadingAttachmentsToken, setLoadingAttachmentsToken] =
    React.useState(true)
  const [loadingExclusions, setLoadingExclusions] = React.useState(true)
  const [loadingMetaConfig, setLoadingMetaConfig] = React.useState(true)
  // eslint-disable-next-line prefer-destructuring
  const tokenInRedux = !!redux.useSelector(redux.selectSetting('token'))
  const isAuthenticated =
    (user && tokenInRedux && !isLoadingAuth0) ||
    process.env.NODE_ENV !== 'production'
  const isAuthenticating = !isAuthenticated

  React.useEffect(() => {
    if (isAuthenticating) {
      return
    }
    void (async (): Promise<void> => {
      try {
        // TODO: Move this to general wrapper or saga
        const exclusions = await apis.fetchExclusions()

        redux.dispatch(
          redux.setSetting({
            key: 'exclusions',
            value: exclusions as unknown as Record<string, readonly string[]>,
          }),
        )

        setLoadingExclusions(false)
      } catch (e) {
        const err = common.processErr(e)
        console.log(`Could not fetch exclusions: ${err}`)
        console.log(e)
        // window.location.reload()
      }
    })()
  }, [isAuthenticating])

  React.useEffect((): void => {
    if (isAuthenticating) {
      return
    }

    apis
      .fetchAttachmentsToken()
      .then((attachmentsToken) => {
        setLoadingAttachmentsToken(false)
        common.setFrontCacheItem('attachmentsToken', attachmentsToken)
      })
      .catch((e) => {
        console.log(e)
        console.log(
          `There was an error fetching the attachments token: ${common.processErr(
            e,
          )}`,
        )
        // window.location.reload()
      })
  }, [isAuthenticating])

  React.useEffect((): void => {
    if (isAuthenticating) {
      return
    }
    apis
      .fetchLastSyncDate()
      .then((lastSync) => {
        redux.dispatch(
          redux.setSetting({
            key: 'lastSync',
            value: lastSync,
          }),
        )
        setLoadingLastSync(false)
      })
      .catch((e) => {
        console.log(e)
        console.log(
          `There was an error fetching the last sync date, please reload this page: ${common.processErr(
            e,
          )}`,
        )
        // window.location.reload()
      })
  })

  React.useEffect((): void => {
    // TODO: Use a test Auth0 env
    if (process.env.NODE_ENV !== 'production') {
      return
    }
    console.log('running redirect effect', user, isLoadingAuth0)
    if (!user && !isLoadingAuth0) {
      console.log('will redirect')
      loginWithPopup().catch((e) => {
        console.log(`Error: ${common.processErr(e)}`)
        // window.location.reload()
      })
    }
  }, [isLoadingAuth0, loginWithPopup, user])

  React.useEffect(() => {
    // TODO: Use a test Auth0 env
    if (process.env.NODE_ENV !== 'production') {
      return
    }
    console.log(`Running token effect`)

    if (isLoadingAuth0 || !user) {
      return
    }
    console.log(`Running getAccessTokenSilently`)
    getAccessTokenSilently({
      audience: 'https://test-graph-backend.keystonestrategy.io',
    })
      .then((token) => {
        // TODO: unsafe?
        if (isMounted()) {
          console.log('dispatching token to store')
          redux.dispatch(
            setSetting({
              key: 'token',
              value: token,
            }),
          )
        }
      })
      .catch((e) => {
        console.log(`Error getting token ${common.processErr(e)}`)
        // window.location.reload()
      })
  }, [getAccessTokenSilently, isLoadingAuth0, isMounted, user])

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  useFetchMetaConfig(isAuthenticating, setLoadingMetaConfig)

  const err = extractErrorIfAny()

  const [dismissedErr, setDismissedErr] = React.useState(false)
  const handleDismissErrorClick = React.useCallback(() => {
    setDismissedErr(true)
  }, [])

  if (err && !dismissedErr) {
    return (
      <div style={otherStyles['root']}>
        <div style={otherStyles['box']}>
          <span>{err}</span>

          <button onClick={handleDismissErrorClick}>{err}</button>
        </div>
      </div>
    )
  }

  const loading =
    loadingAttachmentsToken ||
    loadingExclusions ||
    loadingLastSync ||
    isAuthenticating ||
    loadingMetaConfig

  return <DashboardContent loading={loading} />
}

const extractErrorIfAny = (): string => {
  const query = window.location.href.slice(
    // eslint-disable-next-line prefer-template
    (window.location.origin + '/?').length,
  )

  const parsed = qs.parse(query)

  if (parsed['error']) {
    // eslint-disable-next-line @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
    return `${parsed['error']} -- ${parsed['error_description']}`
  }
  return ''
}

export default Dashboard

const otherStyles: Record<string, React.CSSProperties> = {
  box: {
    alignItems: 'center',
    backgroundColor: '#6b6b6b',
    display: 'flex',
    justifyContent: 'center',
    padding: 80,
  },
  root: {
    alignItems: 'center',
    backgroundColor: '#b0b0b0',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    width: '100%',
  },
}
