/* eslint-disable multiline-comment-style */
// https://codesandbox.io/s/material-demo-eg6mb?file=/demo.tsx:733-760
import React from 'react'

import * as common from '../../../common'

import * as mui from '@mui/material'
// import * as mIcon from '@mui/icons-material'

import styles from './ChipsInput.module.css'

export interface ChipsInputProps {
  readonly autocompleteChoices?: readonly string[]
  readonly chips: readonly string[]
  readonly disabled?: boolean | null | undefined
  readonly fullWidth?: boolean | null | undefined
  readonly label?: string
  readonly onBlur?: () => void
  readonly onChange: (newChips: readonly string[]) => void
  readonly onChangeText?: (text: string) => void
  readonly placeholder: string
}

export default function ChipsInput({
  autocompleteChoices:
    autocompleteChoicesProp = common.emptyArr as readonly string[],
  chips,
  disabled,
  fullWidth,
  label,
  onBlur = common.emptyFn,
  onChange,
  onChangeText = common.emptyFn,
  placeholder,
}: ChipsInputProps): React.ReactElement {
  const [inputValue, setInputValue] = React.useState('')

  const handleKeydown = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (chips.length && !inputValue.length && event.key === 'Backspace') {
        onChange(chips.slice(0, chips.length - 1))
      }

      if (event.key === 'Enter') {
        const newChips = [...chips]

        const dupeIfAny = newChips.indexOf(
          (event.target as HTMLInputElement).value.trim(),
        )

        if (dupeIfAny !== -1) {
          setInputValue('')
          onChangeText('')
          return
        }

        if (
          // eslint-disable-next-line require-unicode-regexp
          !(event.target as HTMLInputElement).value.replace(/\s/g, '').length
        ) {
          return
        }

        newChips.push((event.target as HTMLInputElement).value.trim())
        onChange(newChips)
        setInputValue('')
        onChangeText('')
      }
    },
    [chips, inputValue.length, onChange, onChangeText],
  )

  const handleDelete = React.useCallback(
    (item: string) => () => {
      const newChips = [...chips]
      newChips.splice(newChips.indexOf(item), 1)
      onChange(newChips)
    },
    [chips, onChange],
  )

  const handleInputChange = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value)
      onChangeText(event.target.value)
    },
    [onChangeText],
  )

  const tagsRenderer = React.useMemo(
    () =>
      (
        chipsToRender: readonly string[],
        getTagProps: mui.AutocompleteRenderGetTagProps,
      ): React.ReactNode =>
        chipsToRender.map((chip, index) => (
          <mui.Chip
            // Order of props as in example we adapted from (see bottom of this file)
            label={chip}
            {...getTagProps({ index })}
            classes={disabled ? chipClassesDisabled : common.emptyObj}
            key={chip}
            onDelete={disabled ? undefined : handleDelete(chip)}
            // TODO: Why won't it work via classes or className?
            style={chipStyle}
            tabIndex={-1}
          />
        )),
    [disabled, handleDelete],
  )

  const inputRenderer = React.useMemo(
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    () => (params: mui.AutocompleteRenderInputParams) =>
      (
        <mui.TextField
          {...params}
          InputProps={params.InputProps}
          color="primary"
          disabled={!!disabled}
          fullWidth={!!fullWidth}
          inputProps={{
            ...params.inputProps,
            onChange(e): void {
              // @ts-expect-error TODO
              params.inputProps.onChange?.(e)
              // @ts-expect-error TODO
              handleInputChange(e)
            },
          }}
          label={label}
          onKeyDown={handleKeydown}
          placeholder={chips.length === 0 ? placeholder : ''}
          size="medium"
          variant="outlined"
        />
      ),
    [
      chips.length,
      disabled,
      fullWidth,
      handleInputChange,
      handleKeydown,
      label,
      placeholder,
    ],
  )

  const autoCompleteChoices = React.useMemo((): readonly string[] => {
    const choices = [...autocompleteChoicesProp]
    if (inputValue.length) {
      if (common.isValidDomain(inputValue)) {
        choices.push(inputValue)
      } else {
        const end = inputValue.endsWith('.') ? 'com' : '.com'
        choices.push(inputValue + end)
      }
    }
    // Accounts for user-inputted values
    choices.push(...chips)

    const dedupeChoices = Array.from(new Set(choices))

    // It's just strings
    // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
    dedupeChoices.sort()

    return dedupeChoices
  }, [autocompleteChoicesProp, chips, inputValue])

  const handleChange = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    (_: React.SyntheticEvent, newOptions: readonly string[]): void => {
      onChange(newOptions)
    },
    [onChange],
  )

  return (
    <mui.Autocomplete
      disabled={!!disabled}
      forcePopupIcon={autocompleteChoicesProp.length !== 0}
      getOptionLabel={common.identity}
      id="chips-input"
      limitTags={10}
      // isOptionEqualToValue={isOptionEqualToValue}
      multiple
      noOptionsText="Start typing"
      onBlur={onBlur}
      onChange={handleChange}
      options={autoCompleteChoices}
      renderInput={inputRenderer}
      // TODO: Why won't this work?
      // renderOption={optionRenderer}
      renderTags={tagsRenderer}
      value={chips as string[]}
    />
  )
}

const chipClassesDisabled: mui.ChipProps['classes'] = {
  label: styles['text-gray'],
}

const chipStyle = {
  marginLeft: 12,
  marginRight: 12,
}

// TODO: Why won't this work?

// const checkboxIcon = <mIcon.CheckBoxOutlineBlank fontSize="small" />
// const checkboxIconChecked = <mIcon.CheckBox fontSize="small" />

/*
const optionRenderer = (
  // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
  props: React.HTMLAttributes<HTMLLIElement>,
  option: string,
  // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
  { selected }: mui.AutocompleteRenderOptionState,
): React.ReactElement => (
  <li {...props}>
    <mui.Checkbox
      icon={checkboxIcon}
      checkedIcon={checkboxIconChecked}
      style={checkboxStyle}
      checked={selected}
    />
    {option}
  </li>
)


const checkboxStyle = { marginRight: 8 }

*/
