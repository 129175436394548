import * as common from '../../common'

import { createAction, createSelector, createSlice } from '@reduxjs/toolkit'

import { Action } from './common'

const PREFIX = 'test'

export type ValidRootValue =
  | common.Primitive
  | SettingsMap
  | readonly unknown[]
  | undefined

type SettingsMap = Record<string, common.Primitive | readonly unknown[]>

type Settings = Record<string, ValidRootValue>

export type TestState = common.DeepReadonly<{
  settings: Settings
}>

const initialExclusions: common.Exclusions = {
  excluded_domains: [],
  excluded_emails: [],
}

const initialState: TestState = {
  settings: {
    exclusions: initialExclusions as unknown as Record<
      string,
      readonly string[]
    >,
    exclusionsEnabled: false,
    // Number(null) === 0
    lastSync: Number(localStorage.getItem('lastSync')),
    token: '',
  },
}

const testSlice = createSlice({
  extraReducers(builder) {
    return builder.addCase('persist/REHYDRATE', ({ settings }) => {
      for (const key of Object.keys(settings)) {
        // Rule might be useful later?
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete settings[key]
      }
    })
  },
  initialState,
  name: PREFIX,
  reducers: {
    /**
     * Object.assign(oldSettings, newSettings).
     */
    mergeSettings(
      { settings },
      {
        payload: { newSettings },
      }: Action<{ newSettings: common.Dict<ValidRootValue> }>,
    ) {
      Object.assign(settings, newSettings)
    },
    setSetting(
      { settings },
      {
        payload: { key, value },
      }: Action<{ key: string; value: ValidRootValue }>,
    ) {
      // @ts-expect-error Typescript derped?
      settings[key] = value
    },
  },
})

export const {
  actions: { mergeSettings, setSetting },
  reducer: test,
} = testSlice

export const exampleAction = createAction<{ key: string }>(
  `${PREFIX}/exampleAction`,
)

// #region selectors

type GlobalState = common.DeepReadonly<{
  [PREFIX]: TestState
}>

export const selectAllSettings = ({
  test: { settings },
}: GlobalState): TestState['settings'] => {
  if (Object.keys(settings).length > 0) {
    return settings
  }
  return common.emptyObj
}

export type SelectSettingParams = common.DeepReadonly<{
  key: string
}>

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const makeSelectSetting = () =>
  createSelector(
    selectAllSettings,
    (_: GlobalState, args: SelectSettingParams) => args,
    (
      settings,
      { key },
    ): common.Primitive | SettingsMap | readonly unknown[] | undefined =>
      settings[key],
  )

export const selectSetting =
  (key: string) =>
  (state: GlobalState): ValidRootValue =>
    state[PREFIX].settings[key]

// #endregion selectors
