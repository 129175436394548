import * as FS from 'fs'

const ONE_HOUR_IN_MS = 3600 * 1000

export type Maybe<T> = T | false | null | undefined

export const processErr = (e: unknown): string => {
  if (typeof e === 'string') return e
  if (
    typeof e === 'object' &&
    e !== null &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof (e as Record<string, any>)['message'] === 'string'
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (e as Record<string, any>)['message'] as string
  }

  return 'Unknown Error (Could not parse error)'
}

export const keys = <T extends Dict>(obj: T): readonly (keyof T)[] =>
  Object.keys(obj)

export type ValueOf<T> = T[keyof T]

export const values = <T extends Dict>(o: T): ValueOf<T>[] =>
  Object.values(o) as ValueOf<T>[]

export type Entries<T> = [key: keyof T, value: ValueOf<T>][]

export const entries = <T extends Dict>(o: T): Entries<T> =>
  Object.entries(o) as Entries<T>

// Disable: cannot use infer with [] syntax.
// eslint-disable-next-line @typescript-eslint/array-type
export type DeepReadonly<T> = T extends Array<infer U>
  ? DeepReadonlyArray<U>
  : T extends object
  ? DeepReadonlyObject<T>
  : T

type DeepReadonlyArray<T> = readonly DeepReadonly<T>[]

type DeepReadonlyObject<T> = Readonly<{
  [P in keyof T]: DeepReadonly<T[P]>
}>

/**
 * Can be used as replacement for `{}` while preserving referential equality,
 * e.g. reducing re-renders in React.
 */
export const emptyObj = Object.freeze({})

export type Primitive = boolean | number | string

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const emptyFn = (): void => {}

export type CacheEntry = DeepReadonly<{
  data: unknown
  timeSet: number
}>

const cache: Record<string, CacheEntry | undefined> = {}

export const CACHE_FILE_NAME =
  process.env['NODE_ENV'] === 'test' ? 'cache.test.json' : 'cache.json'

const cacheCheck = (): void => {
  const cacheIsOK = ((): boolean => {
    try {
      JSON.parse(
        FS.readFileSync(CACHE_FILE_NAME, {
          encoding: 'utf8',
        }),
      )

      return true
    } catch {
      console.log('Will rebuild cache')
      return false
    }
  })()

  if (cacheIsOK) {
    return
  }

  FS.writeFileSync(CACHE_FILE_NAME, '{}', 'utf8')
}

export const getFrontCacheItem = (key: string): unknown => {
  const item = cache[key]

  const newerThanAnHour =
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    (item?.timeSet || 0) + ONE_HOUR_IN_MS > Date.now()

  if (item && newerThanAnHour) {
    return item.data
  }

  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
  delete cache[key]

  return null
}

export const setFrontCacheItem = (key: string, data: unknown): void => {
  cache[key] = {
    data,
    timeSet: Date.now(),
  }
}

export const getBackCacheItem = (key: string): unknown => {
  cacheCheck()

  const currCacheStr = FS.readFileSync(CACHE_FILE_NAME, {
    encoding: 'utf8',
  })

  const currCache = JSON.parse(currCacheStr) as Record<
    string,
    CacheEntry | undefined
  >

  const item = currCache[key]

  return item?.data || null
}

export const setBackCacheItem = (key: string, data: unknown): void => {
  cacheCheck()

  const currCacheStr = FS.readFileSync(CACHE_FILE_NAME, {
    encoding: 'utf8',
  })

  const currCache = JSON.parse(currCacheStr) as Record<
    string,
    CacheEntry | undefined
  >

  currCache[key] = {
    data,
    timeSet: Date.now(),
  }

  FS.writeFileSync(CACHE_FILE_NAME, JSON.stringify(currCache, null, 2), 'utf8')
}

export type ReadonlyRecord<K extends number | string | symbol, T> = Readonly<
  Record<K, T>
>

// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
export type Dict<T = unknown> = DeepReadonly<{
  readonly [K: string]: T
}>

export type Dicts<T = unknown> = readonly Dict<T>[]

// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
export interface DictW<T = unknown> {
  [K: string]: T
}

export type DictsW<T = unknown> = Dict<T>[]

export const normalizeTimestampToMs = (timestamp: number): number => {
  if (timestamp === 0) {
    return timestamp
  }

  const t = timestamp.toString()

  if (t.length === 10) {
    // is seconds
    return Number(t) * 1000
  } else if (t.length === 13) {
    // is milliseconds
    return Number(t)
  } else if (t.length === 16) {
    // is microseconds
    return Number(t) / 1000
  }

  console.error(
    `normalizeTimestamp() -> could not interpret timestamp -> ${timestamp}`,
  )

  return Number(t)
}

export type VoidFn = () => void

export const emptyArr = [] as unknown[]

export const identity = <T = unknown>(anything: T): T => anything

export const uniq = <T>(arr: readonly T[]): readonly T[] =>
  Array.from<T>(new Set<T>(arr))

export const stringify = (data: unknown): string =>
  JSON.stringify(data, null, 2)

export const now = (): number => normalizeTimestampToMs(Date.now())

export const alwaysTrue = (): boolean => true

export const alwaysFalse = (): boolean => false

export const pickBy = <T extends Dict>(
  obj: T,
  cb: (item: unknown, key: number | string | symbol) => boolean,
): T => {
  // @ts-expect-error Typescript limitation
  const res: T = {}

  for (const [key, item] of entries(obj)) {
    if (cb(item, key)) {
      res[key] = item
    }
  }
  return res
}

export const paginate = <T>(
  array: readonly T[],
  page: number,
  perPage: number,
): readonly T[] =>
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  array.slice((page - 1) * perPage, page * perPage)

/**
 * Temporarily treat everyone as a single user for the purposes of exclusions.
 */
export const GLOBAL_PLACEHOLDER_USER = 'global-placeholder-user'

export const arrToDict = <T>(keyKey: keyof T, arr: readonly T[]): DictW<T> => {
  const obj: DictW<T> = {}

  for (const item of arr) {
    const key = item[keyKey]

    obj[String(key)] = item
  }

  return obj
}

export const mapValues = <S, T extends Dict>(
  obj: T,
  cb: (item: ValueOf<T>, key: number | string | symbol) => S,
): Dict<S> => {
  const res: DictW<S> = {}

  for (const [key, item] of entries(obj)) {
    res[key as string] = cb(item, key)
  }
  return res as Dict<S>
}

export const keysToMap = <T>(
  keysToUse: readonly string[],
  valueCreator: (key: string) => T,
): Dict<T> => {
  const result: DictW<T> = {}
  for (const key of keysToUse) {
    result[key] = valueCreator(key)
  }
  return result as Dict<T>
}

// eslint-disable-next-line @typescript-eslint/array-type
export type ReadonlyArrayArray<T> = ReadonlyArray<ReadonlyArray<T>>

// Eslint recommends extending Record<string, unknown> but it doesn't work.
// eslint-disable-next-line @typescript-eslint/ban-types
export const groupByContiguous = <T extends Object, K extends keyof T>(
  arr: readonly T[],
  key: K,
  comparator = Object.is,
): ReadonlyArrayArray<T> => {
  if (typeof key !== 'string') {
    throw new TypeError('Only string keys supported')
  }
  if (key.length === 0) {
    throw new Error(`Expected key.length > 0`)
  }
  if (arr.length === 0) {
    // eslint-disable-next-line @typescript-eslint/array-type
    return emptyArr as ReadonlyArrayArray<T>
  }

  const result: T[][] = []

  // eslint-disable-next-line prefer-destructuring, @typescript-eslint/no-non-null-assertion
  let currVal: T[K] = arr[0]![key]
  let currGroup: T[] = []

  for (const item of arr) {
    const val = item[key]
    if (comparator(currVal, val)) {
      currGroup.push(item)
    } else {
      result.push(currGroup)
      currGroup = [item]
      currVal = val
    }
  }

  result.push(currGroup)

  return result
}
