import * as React from 'react'

import * as common from '../../common'

import debounce from 'lodash.debounce'
import qs from 'qs'

import * as redux from '../redux'

export type GetOpts = common.DeepReadonly<{
  param?: string
  query?: Record<string, unknown>
}>

export const get = async <T = unknown>(
  url: string,
  { param, query }: GetOpts = {},
): Promise<T> => {
  // eslint-disable-next-line prefer-destructuring
  const token = redux.getStore().getState().test.settings['token']

  if (!token && process.env.NODE_ENV === 'production') {
    throw new ReferenceError(`Could not find token`)
  }

  let finalUrl = url.endsWith('/') ? url.slice(-1) : url

  param && (finalUrl += `/${param}`)
  query && (finalUrl += `?${qs.stringify(query)}`)

  const headers: common.Dict<string> = {
    Accept: `application/json`,
    ...(token && {
      Authorization: `Bearer ${token as string}`,
    }),
  }

  const res = await fetch(finalUrl, {
    headers,
  })

  let json = {
    err: 'Unknown error',
  }

  try {
    json = (await res.json()) as typeof json
  } catch (e) {
    console.log(e)
    throw new Error('Could not parse JSON, check console.')
  }

  if (res.status >= 400 && res.status <= 499) {
    redux.getStore().dispatch(
      redux.setSetting({
        key: 'token',
        value: false,
      }),
    )
    throw new Error(`HTTP ${res.status}`)
  }

  if (res.status === 503) {
    throw new Error(`503 Probably Heroku's timeout`)
  }

  if (res.status !== 200) {
    throw new Error(json.err || 'Unknown error')
  }

  return json as unknown as T
}

export const post = async (
  url: string,
  body: common.DeepReadonly<Record<string, unknown>>,
  param?: string,
): Promise<Record<string, unknown>> => {
  // eslint-disable-next-line prefer-destructuring
  const token = redux.getStore().getState().test.settings['token']

  if (!token && process.env.NODE_ENV === 'production') {
    throw new ReferenceError(`Could not find token`)
  }

  let finalUrl = url.endsWith('/') ? url.slice(-1) : url

  param && (finalUrl += `/${param}`)

  const headers: common.Dict<string> = {
    Accept: `application/json`,
    'Content-Type': 'application/json',
    ...(token && {
      Authorization: `Bearer ${token as string}`,
    }),
  }

  const res = await fetch(finalUrl, {
    body: JSON.stringify(body),
    headers,
    method: 'POST',
  })

  let json = {
    err: 'Unknown error',
  }

  try {
    json = (await res.json()) as typeof json
  } catch (e) {
    console.log(e)
    throw new Error('Could not parse JSON, check console.')
  }

  if (res.status >= 400 && res.status <= 499) {
    redux.getStore().dispatch(
      redux.setSetting({
        key: 'token',
        value: false,
      }),
    )
    throw new Error(`HTTP ${res.status}: ${json.err || 'unknown err'}`)
  }

  if (res.status === 503) {
    throw new Error(`503 Probably Heroku's timeout`)
  }

  if (res.status !== 200) {
    throw new Error(json.err || 'Unknown error')
  }

  return json
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const useDebounced = <T>(
  cb: (...args: readonly T[]) => void,
  delay?: number | undefined,
) => {
  const isMounted = useIsMounted()
  const inputsRef = React.useRef({ cb, delay })

  React.useEffect(() => {
    inputsRef.current = { cb, delay }
  }, [cb, delay])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useCallback(
    debounce<(...args: readonly T[]) => void>((...args) => {
      // Debounce is an async callback. Cancel it, if in the meanwhile
      // (1) component has been unmounted (see isMounted in snippet)
      // (2) delay has changed
      if (inputsRef.current.delay === delay && isMounted())
        inputsRef.current.cb(...args)
    }, delay),
    [delay, debounce],
  )
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const useIsMounted = () => {
  const isMounted = React.useRef(false)

  React.useEffect((): common.VoidFn => {
    isMounted.current = true

    return (): void => {
      isMounted.current = false
    }
  }, [])

  return React.useCallback((): boolean => isMounted.current, [])
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const useBool = (initialValue: boolean) => {
  const [currValue, setValue] = React.useState<boolean>(initialValue)

  const toggleValue = React.useCallback(() => {
    setValue((v) => !v)
  }, [])

  return [currValue, setValue, toggleValue] as const
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const useObject = <T extends Record<number | string, unknown>>(
  initialValue: T,
) => {
  const [currValue, setValue] = React.useState<T>(initialValue)

  const extendValue = React.useCallback((newValues: Readonly<Partial<T>>) => {
    setValue((_) => ({
      ..._,
      ...newValues,
    }))
  }, [])

  return [currValue, setValue, extendValue] as const
}

export interface CtxMenuEvent {
  currentTarget: HTMLElement
  pageX: number
  pageY: number
  preventDefault: () => void
}
