/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable complexity */
import React from 'react'

import * as common from '../../../common'

import * as mui from '@mui/material'
import * as mIcon from '@mui/icons-material'
import debounce from 'lodash.debounce'
import {
  Link,
  useLocation,
  useOutletContext,
  useParams,
} from 'react-router-dom'

import * as apis from '../../apis'
import * as redux from '../../redux'
import * as utils from '../../utils'
import Pad from '../../comps/Pad'
import Skeleton from '../../comps/Skeleton'
import View from '../../comps/View'

import ChipsInput from './ChipsInput'
import { useSorting, useFilters } from './hooks'
import AdvancedOptions from './AdvancedOptions'

interface InnerGraphDBViewProps {
  /**
   * ID of the current graph view, if undefined, means this component will
   * render the "new" screen.
   */
  id?: string
  /**
   * The lens to display.
   */
  lens: common.GraphDBViewLens
  /**
   * If true, no side-effects will be performed, and an skeleton view will be
   * rendered.
   */
  loading: boolean
}
const useQuery = (): URLSearchParams => {
  const { search } = useLocation()
  return React.useMemo(
    (): URLSearchParams => new URLSearchParams(search),
    [search],
  )
}

const InnerGraphDBView = React.memo<InnerGraphDBViewProps>(
  ({ id, lens, loading }): React.ReactElement | null => {
    const isMounted = utils.useIsMounted()
    const exclusions = redux.useSelector(
      redux.selectSetting('exclusions'),
    ) as unknown as common.Exclusions
    const tabsToHide = redux.useSelector(
      redux.selectSetting('tabsToHide'),
    ) as unknown as string[] | undefined
    const allSettings = redux.useSelector(redux.selectAllSettings)

    const searchInputRef = React.useRef<HTMLInputElement | null>(null)

    const queryDetails = useQuery()
    // const [knowledgeOrgData, setKnowledgeOrgData] = React.useState<
    //   common.Dict<common.Org>
    // >({})
    const [relOrgData, setRelOrgData] = React.useState<common.Dict<common.Org>>(
      {},
    )

    const [viewingProjectsFor, setViewingProjectsFor] = React.useState<
      string | null
    >(null)

    const handleProjectsDialogClose = React.useCallback(() => {
      setViewingProjectsFor(null)
    }, [])

    const [projectsData, setProjectsData] = React.useState<
      common.Dict<common.Neo4JProjects>
    >({})

    const handleProjectsRequest = React.useCallback(
      async (orgDomain: string): Promise<void> => {
        try {
          setViewingProjectsFor(orgDomain)

          const projects = await apis.fetchProjectsForAnOrg(orgDomain)

          setProjectsData((_) => ({
            ..._,
            [orgDomain]: projects,
          }))
        } catch (e) {
          console.log(e)
          alert(common.processErr(e))
        }
      },
      [],
    )

    // // Page+selectedSort to orgs in that page.
    // const [knowledgeOrgByID, setKnowledgeOrgByID] = React.useState<
    //   common.ReadonlyRecord<string, readonly string[]>
    // >({})
    // Page+selectedSort to orgs in that page.
    const [relOrgByID, setRelOrgByID] = React.useState<
      common.ReadonlyRecord<string, readonly string[]>
    >({})

    // TODO: unify children data
    const [relationshipsChildren, setRelationshipsChildren] = React.useState<
      common.Dict<readonly common.IView[]>
    >({})
    const [knowledgeChildren, setKnowledgeChildren] = React.useState<
      common.Dict<readonly common.IView[]>
    >({})

    const [managedByCache, setManagedByCache] = React.useState<
      common.Dict<string[]>
    >(common.emptyObj)
    const [managesCache, setManagesCache] = React.useState<
      common.Dict<string[]>
    >(common.emptyObj)

    // #region controls
    const [page, setPage] = React.useState(1)
    const handlePageChange = React.useCallback(
      (_: unknown, newPage: number): void => {
        setPage(newPage)
      },
      [],
    )
    const [totalPagesRel, setTotalPagesRel] = React.useState(0)

    const [sortMapping, getOnChangeSortMapping] = useSorting()
    const [selectedFilters, filtersState, getToggleFilter] = useFilters()

    const [selectedDirection, setSelectedDirection] = React.useState<
      'inwards' | 'outwards'
    >('inwards')
    const handleDirectionChange = React.useCallback(
      (__: unknown, newDirection: 'inwards' | 'outwards') => {
        // For some reason if you click these buttons too fast they provide null
        // values to the callback (??), handle this.
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (newDirection === null) {
          return
        }

        setSelectedDirection(newDirection)
      },
      [],
    )

    // #endregion controls

    const [currentProjects, setCurrentProjects] = React.useState<
      readonly string[]
    >([])
    const [currentOrgs, setCurrentOrgs] = React.useState<readonly string[]>([])

    React.useEffect(() => {
      if (loading) {
        return common.emptyFn
      }
      let isCancelled = false

      ;(async (): Promise<void> => {
        if (currentOrgs.length === 0) {
          return
        }
        console.log('fetching specific orgs')
        const fullOrgs = await apis.fetchSpecificOrgs({
          orgs: currentOrgs,
          sortMapping,
        })

        const asMap = common.arrToDict('id', fullOrgs)

        setManagedByCache((_) => ({
          ..._,
          ...common.mapValues(asMap, (o) => o.managedBy),
        }))

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (isCancelled) {
          return
        }
        console.log('fetched specific orgs,', {
          fullOrgs,
        })
        // if (lens === 'knowledge') {
        //   setKnowledgeOrgData((_) => ({
        //     ..._,
        //     ...asMap,
        //   }))
        // }
        // if (lens === 'relationships') {
        setRelOrgData((_) => ({
          ..._,
          ...asMap,
        }))
        // }
      })().catch((e) => {
        console.log(`Specific orgs effect -> `, e)
      })

      return () => {
        isCancelled = true
      }
    }, [currentOrgs, lens, loading, sortMapping])

    const fullCurrentOrgs = React.useMemo((): common.Orgs => {
      if (currentOrgs.length === 0) {
        // Reduce re-renders
        return common.emptyArr as common.Orgs
      }
      const orgDataChoices: Record<
        common.GraphDBViewLens,
        common.Dict<common.Org>
      > = {
        inside: {},
        knowledge: relOrgData,
        network: {},
        prospects: {},
        relationships: relOrgData,
      }
      const orgData = orgDataChoices[lens]

      return currentOrgs.map(
        (orgID): common.Org => ({
          ...common.createEmptyOrg(orgID),
          ...orgData[orgID],
        }),
      )
    }, [
      currentOrgs,
      // knowledgeOrgData,
      lens,
      relOrgData,
    ])

    const [orgAutocompleteChoices, setOrgAutocompleteChoices] = React.useState<
      common.Dict<string[]>
    >({})

    const [currentOrgsQuery, setCurrentOrgsQuery] = React.useState('')
    const handleOrgsInputBlur = React.useCallback(() => {
      setCurrentOrgsQuery('')
    }, [])

    const handleOrgsQueryChange = React.useRef(
      debounce((newQuery: string) => {
        if (newQuery.length < 3) {
          return
        }
        if (!isMounted()) {
          return
        }
        console.log('handleOrgsQueryChange')

        apis
          .fetchOrgsAutocomplete(newQuery)
          .then((orgsAutocomplete) => {
            // Could isMounted reference change?
            if (!isMounted()) {
              return
            }
            console.log('handleOrgsQueryChange -> setting ')
            // This works because setters never change references.
            setOrgAutocompleteChoices((_) => ({
              ..._,
              [newQuery]: orgsAutocomplete,
            }))
          })
          .catch((e) => {
            setOrgAutocompleteChoices((_) => ({
              ..._,
              [newQuery]: [common.processErr(e)],
            }))
          })
      }, 500),
    ).current

    React.useEffect(() => {
      if (loading) {
        return
      }
      handleOrgsQueryChange(currentOrgsQuery)
    }, [currentOrgsQuery, handleOrgsQueryChange, loading])

    const [currGraphDBView, setCurrGraphDBView] =
      React.useState<common.GraphDBViewWithoutID | null>(
        id
          ? null
          : {
              canEdit: true,
              name: 'Explore',
              orgs: [],
              people: [],
              projects: [],
            },
      )

    React.useEffect(() => {
      if (loading) {
        return common.emptyFn
      }

      let isCancelled = false

      // If no `id` is provided, then this is the `new` screen.
      if (!id) {
        return common.emptyFn
      }
      console.log('running view fetch effect')
      utils
        .get<common.GraphDBView>(`${apis.BASE_URL}/graph_db_views/${id}`)
        .then((view) => {
          if (isCancelled) {
            return
          }
          console.log('Will set projects orgs and the GraphDBView')
          setCurrentProjects(view.projects)
          setCurrentOrgs(view.orgs)
          setCurrGraphDBView(view)
        })
        .catch((e) => {
          console.log(
            `Could not fetch view with id: ${id} because of: ${common.processErr(
              e,
            )}`,
          )
        })

      return () => {
        console.log('view fetch cleanup')
        isCancelled = true
      }
    }, [id, loading])

    const [{ isSearching, query }, setSearch] = React.useState({
      isSearching: true,
      query: '',
    })
    React.useEffect(() => {
      if (loading) {
        return
      }
      const { current } = searchInputRef
      current && (current.value = '')
      setSearch((_) => ({
        ..._,
        query: '',
      }))
    }, [lens /* clear when lens changes */, loading])
    const handleSearchClick = React.useCallback(() => {
      setSearch(({ isSearching: prevIsSearching }) => ({
        isSearching: !prevIsSearching,
        query: '',
      }))
    }, [])

    const handleQueryChange = React.useMemo(
      () =>
        debounce((e: common.DeepReadonly<{ target: { value: string } }>) => {
          if (!isMounted()) {
            return
          }
          setSearch((_) => ({
            ..._,
            query: e.target.value,
          }))
        }, 500),
      [isMounted],
    )

    const rootNode = React.useMemo((): common.List => {
      const settingsPermutation =
        common.stringify(sortMapping) +
        page.toString() +
        selectedFilters.join(',')

      const root = {
        ...emptyRoot,
      }
      const lensToByIDs: common.ReadonlyRecord<
        common.GraphDBViewLens,
        readonly string[]
      > = {
        inside: [],
        knowledge: relOrgByID[settingsPermutation] || [],
        network: [],
        prospects: [],
        relationships: relOrgByID[settingsPermutation] || [],
      }

      const byIDs = lensToByIDs[lens]

      const lensToData: common.ReadonlyRecord<
        common.GraphDBViewLens,
        common.Dict<common.Org>
      > = {
        inside: {},
        knowledge: relOrgData,
        network: {},
        prospects: {},
        relationships: relOrgData,
      }
      const data = lensToData[lens]

      if (byIDs.length === 0 && fullCurrentOrgs.length === 0) {
        // Reduce re-renders
        return emptyRoot
      }

      const rootOrgs = byIDs.map(
        (orgID) => data[orgID] || common.createEmptyOrg(orgID),
      )

      const lensToChildrenData: common.ReadonlyRecord<
        common.GraphDBViewLens,
        common.Dict<common.IViews>
      > = {
        inside: {},
        knowledge: knowledgeChildren,
        network: {},
        prospects: {},
        relationships: relationshipsChildren,
      }

      const childrenDataToBeUsed = {
        ...lensToChildrenData[lens],
        [settingsPermutation]: fullCurrentOrgs.length
          ? fullCurrentOrgs
          : rootOrgs,
      }
      return populateNodeWithChildren({
        allSettings,
        childrenData: childrenDataToBeUsed,
        exclusions,
        lens,
        managedByCache,
        managesCache,
        node: root,
        page,
        query,
        selectedDirection,
        selectedFilters,
        sortMapping,
      }) as common.List
    }, [
      allSettings,
      exclusions,
      fullCurrentOrgs,
      knowledgeChildren,
      lens,
      managedByCache,
      managesCache,
      page,
      query,
      relOrgByID,
      relOrgData,
      relationshipsChildren,
      selectedDirection,
      selectedFilters,
      sortMapping,
    ])

    // Factor this out
    const handleChildrenRequest = React.useCallback(
      async (parentID: string): Promise<void> => {
        if (lens === 'knowledge') {
          apis
            .fetchAttachmentsToken()
            .then((attachmentsToken) => {
              common.setFrontCacheItem('attachmentsToken', attachmentsToken)
            })
            .catch((e) => {
              console.log(e)
              console.log(
                `There was an error fetching the attachments token: ${common.processErr(
                  e,
                )}`,
              )
              window.location.reload()
            })
        }

        console.log(`handleChildrenRequest(${parentID})`)

        if (allSettings[`error/${parentID}`]) {
          redux.dispatch(
            redux.setSetting({
              key: `error/${parentID}`,
              value: '',
            }),
          )
        }

        const parentNodeType =
          apis.getNodeType(parentID) ||
          (common.isValidDomain(parentID) ? 'org' : null)

        if (!parentNodeType) {
          throw new Error(`Could not find node type: ${parentID}`)
        }
        // TODO: cancellation
        const newData = await templateViewConfigs[selectedDirection][lens][
          parentNodeType
        ]({ id: parentID })

        console.log(`Finished fetching children data`)

        if (!isMounted()) {
          return
        }

        console.log(`Finished fetching children data and component is mounted`)

        if (parentNodeType === 'list') {
          console.error(`Should be unreachable`)
        }

        // TODO: Why could newData ever be void??
        if (!newData) {
          console.error('newData === undefined, returning.')
          return
        }

        if (newData.length === 0) {
          redux.dispatch(
            redux.setSetting({
              key: `error/${parentID}`,
              value: 'No results. These might have been recently excluded.',
            }),
          )
        }

        console.log(`Fetched ${newData.length} items`)

        if (parentNodeType === 'org' && selectedDirection === 'inwards') {
          const nodes = newData as common.Relationships

          const asMap = common.arrToDict('id', nodes)

          setManagedByCache((_) => ({
            ..._,
            ...common.mapValues(asMap, (n) => n.managedBy),
          }))
        }
        if (parentNodeType === 'org' && selectedDirection === 'outwards') {
          const nodes = newData as common.Relationships

          const asMap = common.arrToDict('id', nodes)

          setManagesCache((_) => ({
            ..._,
            ...common.mapValues(asMap, (n) => n.manages),
          }))
        }
        if (
          parentNodeType === 'relationship' &&
          selectedDirection === 'outwards'
        ) {
          const nodes = newData as common.InteractionsWithPersonArr

          const asMap = common.arrToDict('id', nodes)

          setManagedByCache((_) => ({
            ..._,
            ...common.mapValues(asMap, (n) => n.managedBy),
          }))
        }
        if (
          parentNodeType === 'relationship' &&
          selectedDirection === 'inwards'
        ) {
          const nodes = newData as common.InteractionsWithPersonArr

          const asMap = common.arrToDict('id', nodes)

          setManagesCache((_) => ({
            ..._,
            ...common.mapValues(asMap, (n) => n.manages),
          }))
        }

        if (lens === 'knowledge') {
          setKnowledgeChildren((_) => ({
            ..._,
            [parentID]: newData,
          }))
        } else {
          setRelationshipsChildren((_) => ({
            ..._,
            [parentID]: newData,
          }))
        }
      },
      [allSettings, isMounted, lens, selectedDirection],
    )

    const [requestingChildrenFor, setRequestingChildrenFor] = React.useState<
      common.Dict<boolean | undefined>
    >({})

    React.useEffect(() => {
      if (loading) {
        return
      }

      const lensToChildrenData: common.ReadonlyRecord<
        common.GraphDBViewLens,
        common.Dict<common.IViews>
      > = {
        inside: {},
        knowledge: knowledgeChildren,
        network: {},
        prospects: {},
        relationships: relationshipsChildren,
      }

      const childrenData = lensToChildrenData[lens]

      const openMap = common.pickBy(allSettings, (isOpen, key) => {
        const isOpenClosedKey = (key as string).startsWith(`${lens}/open/`)

        return isOpenClosedKey && !!isOpen
      })

      console.log({
        openMap,
        requestingChildrenFor,
        selectedDirection,
      })

      const openNodesToBeRequested = common
        .keys(openMap)
        .map((key) => key.slice(`${lens}/open/`.length))
        .filter((nodeID) => !requestingChildrenFor[nodeID])
        // Filter out nodes with an error set.
        .filter((nodeID) => !allSettings[`error/${nodeID}`])
        .filter((nodeID) => {
          const thisNodesChildren = childrenData[nodeID]
          console.log({ thisNodesChildren })

          // If there's no children at all, request.
          if (!thisNodesChildren) {
            return true
          }

          // Now there might be children but they might be for the wrong direction (inwards vs outwards).

          const nodeType =
            apis.getNodeType(nodeID) ||
            (common.isValidDomain(nodeID) ? 'org' : null)

          if (!nodeType) {
            console.error(`Could not find node type for: ${nodeID}`)
            return false
          }

          const childType = common.viewTypeToChildType[nodeType][lens]

          if (!childType) {
            console.error(`Could not find child type for: ${nodeID}`)
            return false
          }

          const relevantChildren = thisNodesChildren.filter(
            directionDiscriminators[childType][lens][selectedDirection],
          )

          console.log(
            `Relevant children for ${nodeID}: ${relevantChildren.length}`,
          )

          return relevantChildren.length === 0
        })

      const openNodesToBeRequestedAsMap = common.keysToMap(
        openNodesToBeRequested,
        () => true,
      )

      console.log({ openNodesToBeRequestedAsMap })

      if (openNodesToBeRequested.length > 0) {
        setRequestingChildrenFor((_) => ({
          ..._,
          ...openNodesToBeRequestedAsMap,
        }))

        console.log(`Will request children for these open nodes`, {
          lens,
          openNodesToBeRequested,
          selectedDirection,
        })
      }

      openNodesToBeRequested.forEach((openNode) => {
        handleChildrenRequest(openNode)
          .then(() => {
            console.log(`Finished request for ${openNode}`)

            setRequestingChildrenFor((_) => ({
              ..._,
              [openNode]: false,
            }))
          })
          .catch((e) => {
            console.log(
              `Tried to request children for open node: ${openNode} but failed -> `,
              e,
            )

            const errMsg = common.processErr(e)
            redux.dispatch(
              redux.setSetting({
                key: `error/${openNode}`,
                value: errMsg,
              }),
            )
          })
      })
    }, [
      allSettings,
      handleChildrenRequest,
      knowledgeChildren,
      lens,
      loading,
      requestingChildrenFor,
      relationshipsChildren,
      selectedDirection,
    ])

    const handleAssignManager = React.useCallback(
      (nodeID: string) => {
        const theNode = common.findNodeFromRoot(rootNode, nodeID)
        if (!theNode) {
          console.error(`Could not find node from root: ${nodeID}`)
          return
        }

        let targetExternal = ''
        let ownerInternal = ''

        if (theNode.type === 'interactionsWithPerson') {
          const { id: interactionID } = theNode

          if (selectedDirection === 'inwards') {
            const [external, internal] = interactionID.split('<->')

            targetExternal = external!
            ownerInternal = internal!

            const parentRelationshipID = targetExternal

            setManagedByCache((_) => ({
              ..._,
              [parentRelationshipID]: common.uniq(
                (_[parentRelationshipID] || []).concat(ownerInternal),
              ),
            }))
          }
          // Should be unreachable?
          if (selectedDirection === 'outwards') {
            throw new Error('Should be unreachable')
          }
        }
        if (theNode.type === 'relationship') {
          if (selectedDirection === 'inwards') {
            throw new Error('Should be unreachable.')
          }
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (selectedDirection === 'outwards') {
            const [orgDomain, internalEmailAddress] = theNode.id.split('<->')

            targetExternal = orgDomain!
            ownerInternal = internalEmailAddress!

            setManagedByCache((_) => ({
              ..._,
              [orgDomain!]: common.uniq(
                (_[orgDomain!] || []).concat(ownerInternal),
              ),
            }))
          }
        }

        setManagesCache((_) => ({
          ..._,
          [nodeID]: common.uniq((_[nodeID] || []).concat(targetExternal)),
        }))

        apis.assignManager(targetExternal, ownerInternal).catch((e) => {
          console.log(`handleAssignManager() -> `, e)
        })
      },
      [rootNode, selectedDirection],
    )

    const handleExpand = React.useCallback(
      (nodeID: string): void => {
        const isOrg = common.isValidDomain(nodeID)

        if (isOrg) {
          setCurrentOrgs((_) => common.uniq([..._, nodeID]))
        }

        const open = Boolean(allSettings[`${lens}/open/${nodeID}`])

        redux.dispatch(
          redux.setSetting({
            key: `${lens}/open/${nodeID}`,
            value: !open,
          }),
        )
      },
      [allSettings, lens],
    )

    const handleRetry = React.useCallback(
      (nodeID: string): void => {
        const open = Boolean(allSettings[`${lens}/open/${nodeID}`])

        // TODO: Do this clearer (when redux). When search is active (query
        // present), this button is not "retry" but "clear search"
        if (query && open) {
          const { current } = searchInputRef
          current && (current.value = '')
          setSearch((_) => ({
            ..._,
            query: '',
          }))
        }

        if (!query && open) {
          redux.dispatch(
            redux.setSetting({
              key: `error/${nodeID}`,
              value: '',
            }),
          )
        }
      },
      [allSettings, lens, query],
    )

    React.useEffect(() => {
      if (loading) {
        return common.emptyFn
      }
      // No need to load general data if it's a practice/view.
      if (id) {
        return common.emptyFn
      }
      // No need to load general data if user is only viewing specific orgs
      if (currentOrgs.length) {
        return common.emptyFn
      }
      let isCancelled = false

      ;(async (): Promise<void> => {
        console.log('fetching relationships data', {
          page,
          sortMapping,
        })
        const { orgs, totalPages: totalPagesFetched } =
          await apis.fetchInteractionsByOrg({
            page,
            perPage: common.ITEMS_PER_PAGE,
            selectedFilters,
            sortMapping,
          })
        console.log('fetched relationships data', {
          orgs,
          page,
          sortMapping,
        })
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (isCancelled) {
          return
        }
        const asMap = common.arrToDict('id', orgs)

        setManagedByCache((_) => ({
          ..._,
          ...common.mapValues(asMap, (o) => o.managedBy),
        }))

        console.log(`will set relationships data: ${orgs.length}`)
        setRelOrgByID((_) => ({
          ..._,
          [common.stringify(sortMapping) +
          page.toString() +
          selectedFilters.join(',')]: orgs.map((org) => org.id),
        }))
        setRelOrgData((_) => ({
          ..._,
          ...common.arrToDict('id', orgs),
        }))
        setTotalPagesRel(totalPagesFetched)
      })().catch((e) => {
        console.log(`useOrgsFetcher() -> `, e)
      })

      return () => {
        isCancelled = true
      }
    }, [
      currentOrgs.length,
      id,
      lens,
      loading,
      page,
      selectedFilters,
      sortMapping,
    ])

    const isLoading = loading || !currGraphDBView

    return (
      <>
        <mui.Dialog
          onClose={handleProjectsDialogClose}
          open={!!viewingProjectsFor}
        >
          <mui.DialogTitle>Projects</mui.DialogTitle>

          <mui.List
            subheader={<li />}
            sx={{ pt: 0 }}
          >
            <li key={'complete'}>
              <ul>
                <mui.ListSubheader disableGutters>Complete</mui.ListSubheader>

                {(projectsData[viewingProjectsFor || ''] || [])
                  .filter((project) => project.stageName === 'Complete')
                  .map((project) => (
                    <mui.ListItem key={project._key}>
                      <mui.ListItemText
                        primary={project.name}
                        secondary={project.projectCode.replace(
                          project.name,
                          '',
                        )}
                      />
                    </mui.ListItem>
                  ))}
              </ul>
            </li>

            <li key={'did-not-close'}>
              <ul>
                <mui.ListSubheader disableGutters>
                  Did Not Close
                </mui.ListSubheader>

                {(projectsData[viewingProjectsFor || ''] || [])
                  .filter((project) => project.stageName === 'Did Not Close')
                  .map((project) => (
                    <mui.ListItem key={project._key}>
                      <mui.ListItemText
                        primary={project.name}
                        secondary={project.projectCode.replace(
                          project.name,
                          '',
                        )}
                      />
                    </mui.ListItem>
                  ))}
              </ul>
            </li>
          </mui.List>
        </mui.Dialog>

        <div style={styles['root']}>
          <div style={styles['header']}>
            <Skeleton
              loading={isLoading}
              variant="text"
            >
              {
                <h2>
                  {isLoading
                    ? 'Loading...'
                    : currGraphDBView.name || 'Loading...'}
                </h2>
              }
            </Skeleton>

            <Skeleton
              loading={isLoading}
              variant="rectangular"
            >
              <mui.Button
                onClick={common.emptyFn}
                variant="outlined"
              >
                Save
              </mui.Button>
            </Skeleton>
          </div>

          <div style={styles['inputs']}>
            <Skeleton
              loading={isLoading}
              variant="rectangular"
              width="100%"
            >
              <ChipsInput
                chips={currentProjects}
                fullWidth
                label={
                  currentProjects.length
                    ? `Projects (${currentProjects.length}):`
                    : 'Projects'
                }
                onChange={setCurrentProjects}
                // placeholder={isEditing ? '26497 US v. FB - Direction Counsel' : ''}
                placeholder=""
              />
            </Skeleton>

            <Pad y={16} />

            <Skeleton
              loading={isLoading}
              variant="rectangular"
              width="100%"
            >
              <ChipsInput
                autocompleteChoices={orgAutocompleteChoices[currentOrgsQuery]}
                chips={currentOrgs}
                fullWidth
                label={
                  currentOrgs.length
                    ? `Organizations (${currentOrgs.length}):`
                    : 'Organizations'
                }
                onBlur={handleOrgsInputBlur}
                onChange={setCurrentOrgs}
                onChangeText={setCurrentOrgsQuery}
                placeholder="microsoft.com"
              />
            </Skeleton>
            <Pad y={16} />
          </div>

          <Pad y={24} />

          <Skeleton
            loading={isLoading}
            variant="rectangular"
            width="100%"
          >
            <mui.Stack
              alignItems="center"
              spacing={2}
            >
              <mui.Pagination
                count={totalPagesRel}
                page={page}
                onChange={handlePageChange}
                style={currentOrgs.length > 0 ? styles['opacity0'] : undefined}
              />
            </mui.Stack>
          </Skeleton>

          <Pad y={24} />

          <Skeleton
            // height="240px"
            loading={isLoading}
            variant="rectangular"
            width="100%"
          >
            {/* The replace prop doesn't completely kill the tabs? */}
            <mui.Tabs
              centered
              value={lens}
            >
              {lensesOrder.map((lensValue) => {
                if (
                  tabsToHide?.includes(lensValue) &&
                  !queryDetails.get(lensValue)
                ) {
                  return null
                }
                if (
                  tabsToHide?.includes(lensValue) &&
                  queryDetails.get(lensValue)
                ) {
                  const updatedTabsToHide = tabsToHide.filter(
                    (item) => item !== lensValue,
                  )
                  redux.dispatch(
                    redux.setSetting({
                      key: 'tabsToHide',
                      value: updatedTabsToHide,
                    }),
                  )
                }
                return (
                  <mui.Tab
                    component={Link}
                    disabled={
                      lensValue !== 'knowledge' && lensValue !== 'relationships'
                    }
                    key={lensValue}
                    label={lensToLabel[lensValue]}
                    // Remember `to` without a leading `/` appends rather than replace
                    to={`/views/${id || 'new'}/${lensValue}`}
                    value={lensValue}
                  />
                )
              })}

              <mui.Tab
                aria-label="search"
                onClick={handleSearchClick}
                icon={
                  <mIcon.TuneRounded
                    color={isSearching ? 'action' : 'primary'}
                    fontSize="medium"
                  />
                }
              />
            </mui.Tabs>
          </Skeleton>

          {isSearching && (
            <AdvancedOptions
              hideDirectionControl={lens !== 'relationships'}
              isLoading={isLoading}
              sortMapping={sortMapping}
              getOnChangeSortMapping={getOnChangeSortMapping}
              filtersState={filtersState}
              getToggleFilter={getToggleFilter}
              searchInputRef={searchInputRef}
              // @ts-expect-error TODO: Fix type
              handleQueryChange={handleQueryChange}
              // @ts-expect-error TODO: Fix type
              handleDirectionChange={handleDirectionChange}
              selectedDirection={selectedDirection}
            />
          )}
          <Pad y={8} />

          <Skeleton
            height={240}
            loading={isLoading || rootNode.children.length === 0}
            replace
            variant="rectangular"
            width="100%"
          >
            <mui.Grid
              container
              spacing={3}
            >
              <mui.Grid
                item
                xs={12}
              >
                <mui.Paper sx={styles['paper']}>
                  <View
                    isSearching={!!query}
                    onAssignManager={handleAssignManager}
                    onProjectsRequest={handleProjectsRequest}
                    onExpand={handleExpand}
                    onRetry={handleRetry}
                    view={rootNode}
                  />
                </mui.Paper>
              </mui.Grid>
            </mui.Grid>
          </Skeleton>
        </div>
      </>
    )
  },
)

const styles: Record<string, React.CSSProperties> = {
  centeredCol: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  dashedBox: {
    // @ts-expect-error TODO
    '&:hover': {
      cursor: 'pointer',
    },
    borderColor: 'grey',
    borderStyle: 'dashed',
    borderWidth: 1,
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  inputs: {
    display: 'flex',
    flexDirection: 'column',
  },
  opacity0: {
    opacity: 0,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 24,
    marginRight: 24,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  tabs: {
    width: '100%',
  },
}

const lensToLabel: Record<common.GraphDBViewLens, string> = {
  inside: 'Inside sales',
  knowledge: 'Knowledge',
  network: 'Network',
  prospects: 'Prospects',
  relationships: 'Relationships',
}

const lensesOrder: readonly common.GraphDBViewLens[] = [
  'network',
  'relationships',
  'knowledge',
  'prospects',
  'inside',
] as const

interface GraphDBViewParams extends Record<string, string | undefined> {
  /**
   * ID of the current graph view, if undefined, means this component will
   * render the "new" screen.
   */
  id?: string
  /**
   * The lens to display.
   */
  lens?: common.GraphDBViewLens
}

export default React.memo((): React.ReactElement => {
  const { id, lens } = useParams<GraphDBViewParams>()

  const loading = useOutletContext<boolean>()

  const lensToUse = lens || common.DEFAULT_LENS

  /**
   * The key resets the state to initialState when the route changes from say
   * 'a' to 'b'. This is simpler than handling the reset inside the component.
   * This consumes resources but is a worthwhile trade-off just to avoid bugs
   * then the component grows/changes in the future.
   */

  return (
    <InnerGraphDBView
      id={id}
      key={id}
      lens={lensToUse}
      loading={!!loading}
    />
  )
})

interface DiscriminatorTuple {
  readonly inwards: (view: common.IView) => boolean
  readonly outwards: (view: common.IView) => boolean
}

const noopDiscriminatorTuple: DiscriminatorTuple = {
  inwards: common.alwaysTrue,
  outwards: common.alwaysTrue,
}

type Discriminator = Record<common.GraphDBViewLens, DiscriminatorTuple>

const noopDiscriminator: Discriminator = {
  inside: noopDiscriminatorTuple,
  knowledge: noopDiscriminatorTuple,
  network: noopDiscriminatorTuple,
  prospects: noopDiscriminatorTuple,
  relationships: noopDiscriminatorTuple,
}

const directionDiscriminators: Record<common.IView['type'], Discriminator> = {
  convo: noopDiscriminator,
  document: noopDiscriminator,
  interactionsWithPerson: {
    ...noopDiscriminator,
    relationships: {
      ...noopDiscriminatorTuple,
      inwards({ id: emailAddressTuple }: common.IDHaver) {
        const [, secondEmail] = emailAddressTuple.split('<->')

        if (!secondEmail) {
          throw new Error(
            `Could not correctly filter data in terms of direction: ${emailAddressTuple}`,
          )
        }
        return common.isKeystoneEmail(secondEmail)
      },
      outwards({ id: emailAddressTuple }: common.IDHaver) {
        const [firstEmail] = emailAddressTuple.split('<->')

        if (!firstEmail) {
          throw new Error(
            `Could not correctly filter data in terms of direction: ${emailAddressTuple}`,
          )
        }
        return common.isKeystoneEmail(firstEmail)
      },
    },
  },
  list: noopDiscriminator,
  org: noopDiscriminator,
  personForADoc: noopDiscriminator,
  relationship: {
    ...noopDiscriminator,
    relationships: {
      ...noopDiscriminatorTuple,
      inwards({ id: externalEmailAddress }: common.IDHaver) {
        return (
          common.isValidEmail(externalEmailAddress) &&
          !common.isKeystoneEmail(externalEmailAddress)
        )
      },
      outwards({ id: domainEmailTuple }: common.IDHaver) {
        const [domain, keystoneEmail] = domainEmailTuple.split('<->')

        return (
          common.isValidDomain(domain) &&
          common.isValidEmail(keystoneEmail) &&
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          common.isKeystoneEmail(keystoneEmail!)
        )
      },
    },
  },
}

type PopulateNodeWithChildrenOpts = common.DeepReadonly<{
  allSettings: redux.TestState['settings']
  childrenData: common.Dict<readonly common.IView[]>
  exclusions: common.Exclusions
  lens: common.GraphDBViewLens
  managedByCache: common.Dict<string[]>
  managesCache: common.Dict<string[]>
  node: common.IView
  page: number
  query: string
  selectedDirection: 'inwards' | 'outwards'
  selectedFilters: string[]
  sortMapping: common.SortMapping
}>

const populateNodeWithChildren = ({
  allSettings,
  childrenData,
  exclusions,
  lens,
  managedByCache,
  managesCache,
  node: __node,
  page,
  query,
  selectedDirection,
  selectedFilters,
  sortMapping,
}: PopulateNodeWithChildrenOpts): common.IView => {
  const clone = {
    ...__node,
  }
  const childrenKey =
    clone.id === 'root'
      ? common.stringify(sortMapping) +
        page.toString() +
        selectedFilters.join(',')
      : clone.id

  clone.children = (childrenData[childrenKey] || []).filter(
    (child): boolean => {
      switch (child.type) {
        case 'org':
          return !exclusions.excluded_domains.includes(child.id)
        case 'relationship':
          return !exclusions.excluded_emails.includes(child.id)
        default:
          return true
      }
    },
  )

  if (
    clone.type === 'interactionsWithPerson' ||
    clone.type === 'org' ||
    clone.type === 'relationship'
  ) {
    clone.managedBy = managedByCache[clone.id] || clone.managedBy
  }
  if (
    clone.type === 'interactionsWithPerson' ||
    clone.type === 'relationship'
  ) {
    clone.manages = managesCache[clone.id] || clone.manages
  }

  const childType = common.viewTypeToChildType[clone.type][lens]

  if (childType) {
    const oldChildren = clone.children
    clone.children = clone.children.filter(
      directionDiscriminators[childType][lens][selectedDirection],
    )
    if (oldChildren.length > 0 && clone.children.length === 0) {
      console.log(`filtered children according to direction:`, {
        childType,
        id: __node.id,
        lens,
        newChildren: clone.children,
        oldChildren,
        page,
        selectedDirection,
        type: __node.type,
      })
    }
  }
  const searchKeysOrPaths = childType && viewToSearchKeys[childType]
  if (childType && query && searchKeysOrPaths) {
    console.log('Filtering with query: ', query)

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const results = clone.children.filter((child) => {
      const open = Boolean(allSettings[`${lens}/open/${child.id}`])
      if (open) return true
      // eslint-disable-next-line no-unreachable-loop
      for (const key of searchKeysOrPaths) {
        let str = ''

        for (const bit of key.split('.')) {
          // @ts-expect-error TODO
          str = child[bit] as unknown as string
        }
        if (typeof str !== 'string') {
          console.error(
            `Search key not an string`,
            childType,
            searchKeysOrPaths,
            child,
          )
          return false
        }
        if (str.toLowerCase().includes(query.toLowerCase())) {
          return true
        }
      }

      return false
    })
    clone.children = results
  }

  clone.children = clone.children.map((child) =>
    populateNodeWithChildren({
      allSettings,
      childrenData,
      exclusions,
      lens,
      managedByCache,
      managesCache,
      node: child,
      page,
      query,
      selectedDirection,
      selectedFilters,
      sortMapping,
    }),
  )

  return clone
}

// Knowledge
/**
 * Relationships: orgs -> People -> interactions
 * Knowledge: orgs -> docs -> people
 * Document icons (pdf excel word etc)
 * Company icons via domain.com/favicon.ico
 */

type ViewGetter =
  | ((params: common.IDHaver) => Promise<common.IViews>)
  | ((params: common.IDHaver) => void)

type TemplateViewConfig = common.ReadonlyRecord<common.IViewType, ViewGetter>

const noopTemplateViewConfig: TemplateViewConfig = {
  convo: common.emptyFn,
  document: common.emptyFn,
  interactionsWithPerson: common.emptyFn,
  list: common.emptyFn,
  org: common.emptyFn,
  personForADoc: common.emptyFn,
  relationship: common.emptyFn,
}

const templateViewConfigs: common.ReadonlyRecord<
  'inwards' | 'outwards',
  common.ReadonlyRecord<common.GraphDBViewLens, TemplateViewConfig>
> = {
  inwards: {
    inside: {
      ...noopTemplateViewConfig,
      org: apis.fetchDocumentsForAnOrg,
    },
    knowledge: {
      ...noopTemplateViewConfig,
      document: apis.fetchPeopleForADoc,
      org: apis.fetchDocumentsForAnOrg,
    },
    network: noopTemplateViewConfig,
    prospects: noopTemplateViewConfig,
    relationships: {
      ...noopTemplateViewConfig,
      interactionsWithPerson: apis.fetchEmails,
      org: apis.fetchRelationshipsForAnOrg,
      relationship: apis.fetchInteractionsWithPerson,
    },
  },
  outwards: {
    inside: noopTemplateViewConfig,
    knowledge: {
      ...noopTemplateViewConfig,
      document: apis.fetchPeopleForADoc,
      org: apis.fetchDocumentsForAnOrg,
    },
    network: noopTemplateViewConfig,
    prospects: noopTemplateViewConfig,
    relationships: {
      ...noopTemplateViewConfig,
      interactionsWithPerson: apis.fetchEmails,
      org: apis.fetchOutreachForAnOrg,
      relationship: apis.fetchInteractionsWithKeystoneEmployee,
    },
  },
}

const viewToSearchKeys: Readonly<
  Partial<Record<common.IViewType, readonly string[]>>
> = {
  convo: ['subject'],
  document: ['name'],
  interactionsWithPerson: [
    'address',
    'externalAddress',
    'name',
    'target.emailAddress',
    'target.name',
  ],
  relationship: ['name', 'address'],
}

const emptyRoot: common.List = {
  children: [],
  endDateTime: 0,
  id: 'root',
  queries: [],
  startDateTime: 0,
  total: 0,
  type: 'list',
}
