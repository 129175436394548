import React from 'react'
import * as common from '../../../common'

type ChangeColumnDirectionType = (
  column: keyof common.Org,
) => React.MouseEventHandler<HTMLButtonElement>

type UseSortingReturn = [common.SortMapping, ChangeColumnDirectionType]

export const useSorting = (): UseSortingReturn => {
  const [sortMapping, setSortMapping] = React.useState<common.SortMapping>(
    common.defaultSortMapping,
  )
  const changeColumnDirection = React.useCallback(
    (column: keyof common.Org): React.MouseEventHandler<HTMLButtonElement> =>
      () => {
        const newState = sortMapping.map((e) => {
          const direction =
            e.direction === common.SortDirection.ASC
              ? common.SortDirection.DESC
              : common.SortDirection.ASC
          const orgSortMethod =
            e.column === column
              ? {
                  column,
                  direction,
                }
              : e
          return orgSortMethod
        })
        setSortMapping(newState)
      },
    [sortMapping, setSortMapping],
  )
  console.log('useSorting > sortMapping: ', sortMapping)
  return [sortMapping, changeColumnDirection]
}

type ObjectFiltersType = common.ReadonlyRecord<string, boolean>
type GetToggleFilterType = (
  filter: string,
) => React.ChangeEventHandler<HTMLInputElement>

type UseOnlyClientsFilterReturn = [
  string[],
  ObjectFiltersType,
  GetToggleFilterType,
]

export const useFilters = (): UseOnlyClientsFilterReturn => {
  const [filtersState, setObjectFilters] = React.useState<ObjectFiltersType>({
    has_attachments: true,
    more_than_one_message: false,
    only_clients: true,
  })
  const getToggleFilter = React.useCallback(
    (filter: string) => () => {
      setObjectFilters((prev) => ({
        ...prev,
        [filter]: !prev[filter],
      }))
    },
    [setObjectFilters],
  )
  const selectedFilters = React.useMemo(
    () =>
      Object.entries(filtersState)
        .filter((e) => e[1])
        .map((e) => e[0]),
    [filtersState],
  )
  return [selectedFilters, filtersState, getToggleFilter]
}
