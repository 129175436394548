/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as common from '../../common'

import * as utils from '../utils'

export const BASE_URL: string = process.env['REACT_APP_BASE_URL'] || ''

if (!BASE_URL) {
  throw new Error('REACT_APP_BASE_URL env var not provided.')
}

export interface OrgsQuery {
  readonly page?: number
  readonly perPage?: number
  readonly sortMapping: common.SortMapping
  readonly selectedFilters: readonly string[]
}

export interface OrgsRes {
  readonly orgs: common.Orgs
  readonly perPage?: number
  readonly sortMapping: common.SortMapping
  readonly totalPages: number
}

const nodeToType: common.DictW<common.IViewType> = {}

const addToNodeToType = (nodes: common.IViews): void => {
  const asDict = common.arrToDict('id', nodes)
  const asNodeToType = common.mapValues(asDict, (node) => node.type)
  Object.assign(nodeToType, asNodeToType)
}

export const getNodeType = (id: string): common.IViewType | null =>
  nodeToType[id] || null

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchInteractionsByOrg = async ({
  page = 1,
  perPage = common.ITEMS_PER_PAGE,
  sortMapping = common.defaultSortMapping,
  selectedFilters = [],
}: OrgsQuery): Promise<common.DeepReadonly<OrgsRes>> => {
  console.log('fetchInteractionsByOrg()')
  const query: common.GetOrgQuery = {
    page: page.toString(),
    per_page: perPage.toString(),
    selected_filters: selectedFilters.join(','),
    sort_mapping: JSON.stringify(sortMapping),
  }

  const queryParams = new URLSearchParams(query as never)

  const res = await utils.get<common.GetOrgRes>(
    `${BASE_URL + common.ORG_ROUTE}?${queryParams.toString()}`,
  )

  const { interactionsByOrg, sort_mapping, total_pages } = res

  addToNodeToType(interactionsByOrg)

  return {
    orgs: interactionsByOrg,
    perPage,
    sortMapping: sort_mapping,
    totalPages: total_pages,
  }
}

interface SpecificOrgParams {
  readonly orgs: readonly string[]
  readonly sortMapping: common.SortMapping
}

export const fetchSpecificOrgs = async ({
  orgs,
  sortMapping,
}: SpecificOrgParams): Promise<common.Orgs> => {
  const queryParams: common.GetOrgQuery = {
    orgs: orgs.join(','),
    sort_mapping: JSON.stringify(sortMapping),
  }
  const query = new URLSearchParams(queryParams as never)

  const res = await utils.get<common.GetOrgRes>(
    `${BASE_URL + common.ORG_ROUTE}?${query.toString()}`,
  )
  addToNodeToType(res.interactionsByOrg)

  return res.interactionsByOrg
}

export const fetchRelationshipsForAnOrg = async ({
  id,
}: common.IDHaver): Promise<common.Relationships> => {
  const res = await utils.get<common.GetRelationshipsForAnOrgRes>(
    `${BASE_URL + common.RELATIONSHIPS_FOR_AN_ORG_ROUTE}/${id}`,
  )
  addToNodeToType(res.relationshipsForAnOrg)

  return res.relationshipsForAnOrg
}

export const fetchOutreachForAnOrg = async ({
  id: orgDomain,
}: common.IDHaver): Promise<common.Relationships> => {
  const res = await utils.get<common.GetOutreachForAnOrgRes>(
    `${BASE_URL + common.OUTREACH_FOR_AN_ORG}/${orgDomain}`,
  )
  addToNodeToType(res.outreach_for_an_org)

  return res.outreach_for_an_org
}

export const fetchInteractionsWithPerson = async ({
  id,
}: common.IDHaver): Promise<common.InteractionsWithPersonArr> => {
  const res = await utils.get<common.GetInteractionsWithPersonRes>(
    `${BASE_URL + common.INTERACTIONS_WITH_PERSON}/${id}`,
  )
  addToNodeToType(res.interactionsWithPerson)
  return res.interactionsWithPerson
}

export const fetchInteractionsWithKeystoneEmployee = async ({
  id,
}: common.IDHaver): Promise<common.InteractionsWithPersonArr> => {
  const [externalOrgDomain, employeeEmailAddress] = id.split('<->')

  if (!externalOrgDomain || !common.isValidDomain(externalOrgDomain || '')) {
    throw new TypeError(
      `fetchInteractionsWithKeystoneEmployee() -> Could not extract externalOrgDomain from id, got id: ${id}`,
    )
  }
  if (!employeeEmailAddress) {
    throw new TypeError(
      `fetchInteractionsWithKeystoneEmployee() -> Could not extract employeeEmailAddress from id, got id: ${id}`,
    )
  }
  const res = await utils.get<common.GetInteractionsWithKeystoneEmployeeRes>(
    `${
      BASE_URL + common.INTERACTIONS_WITH_KEYSTONE_EMPLOYEE
    }/${externalOrgDomain}/${employeeEmailAddress}`,
  )
  addToNodeToType(res.interactions_with_keystone_employee)
  return res.interactions_with_keystone_employee
}

export const fetchEmails = async ({
  id,
}: common.IDHaver): Promise<common.Convos> => {
  // Target is external, source is internal (Keystone), but this relationship is
  // flipped when the graph is flipped (so as to differentiate between flipped
  // and non flipped interactions )
  const emails = id.split('<->')

  const isValidEmails = emails.length === 2 && emails.every(common.isValidEmail)

  if (!isValidEmails) {
    throw new TypeError(
      'Could not correctly extract emails from interactionsWithPerson.id',
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const target = emails.find((address) => !common.isKeystoneEmail(address))!
  const source = emails.find(common.isKeystoneEmail)

  if (!source) {
    throw new TypeError(
      'Could not correctly extract keystone email from interactionsWithPerson.id',
    )
  }
  const res = await utils.get<common.GetFindEmailsRes>(
    `${BASE_URL + common.FIND_EMAILS}/${target}/${source}`,
  )
  addToNodeToType(res.emails)
  return res.emails
}

export const fetchDocumentsForAnOrg = async ({
  id,
}: common.IDHaver): Promise<common.Documents> => {
  const res = await utils.get<{
    documentsForAnOrg: common.Documents
  }>(`${BASE_URL}/documents_for_an_org/${id}`)

  addToNodeToType(res.documentsForAnOrg)

  return res.documentsForAnOrg
}

export const fetchPeopleForADoc = async ({
  id: docID,
}: common.IDHaver): Promise<common.PeopleForADoc> => {
  const [, convoIdAndIds] = docID.split('_$$_>')
  const [, ids] = convoIdAndIds!.split('_$_>')
  const [id] = ids!.split('/_$_/')!

  console.log('fetchPeopleForADoc', {
    convoIdAndIds,
    id,
    ids,
  })
  const res = await utils.get<{ peopleForThisDoc: common.PeopleForADoc }>(
    `${BASE_URL}/people_for_a_doc/${id!}`,
  )

  addToNodeToType(res.peopleForThisDoc)

  return res.peopleForThisDoc
}

export const fetchExclusions = async (): Promise<common.Exclusions> => {
  const res = await utils.get<common.GetExclusionsRes>(`${BASE_URL}/exclusions`)

  return res.exclusions
}

export const excludeDomain = async (domain: string): Promise<void> => {
  const body: common.PostExcludeDomainBody = {
    domain,
  }
  ;(await utils.post(
    `${BASE_URL}/exclude_domain`,
    body,
  )) as common.PostExcludeDomainRes
}

export const excludeEmail = async (emailAddress: string): Promise<void> => {
  const body: common.PostExcludeEmailBody = {
    emailAddress,
  }
  ;(await utils.post(
    `${BASE_URL}/exclude_email`,
    body,
  )) as common.PostExcludeEmailRes
}

export const fetchLastSyncDate = async (): Promise<number> => {
  const { last_sync_date } = await utils.get<{
    last_sync_date: number
  }>(`${BASE_URL}/last_sync_date`)

  localStorage.setItem('lastSync', String(last_sync_date))

  return last_sync_date
}

export const fetchOrgsAutocomplete = async (
  query: string,
): Promise<readonly string[]> => {
  const queryObj: common.GetOrgsAutocompleteQuery = {
    q: query,
  }
  const urlSearchParams = new URLSearchParams(queryObj)

  const res = await utils.get<common.GetOrgsAutocompleteRes>(
    `${
      BASE_URL + common.ORGS_AUTOCOMPLETE_ROUTE
    }?${urlSearchParams.toString()}`,
  )

  return res.orgs
}

export const fetchAttachmentsToken = async (): Promise<string> => {
  const res = await utils.get<{ token: string }>(
    `${BASE_URL}/attachments_token`,
  )

  return res.token
}

export const assignManager = async (
  targetExternal: string,
  managerInternal: string,
): Promise<void> => {
  const body: common.PostAssignManagerBody = {
    manager_internal: managerInternal,
    target_external: targetExternal,
  }

  await utils.post(BASE_URL + common.ASSIGN_MANAGER_ROUTE, body)
}

export const fetchProjectsForAnOrg = async (
  orgDomain: string,
): Promise<common.Neo4JProjects> => {
  const res = await utils.get<common.GetProjectsForAnOrgRes>(
    `${BASE_URL + common.PROJECTS_FOR_AN_ORG}/${orgDomain}`,
  )

  return res.projectsForAnOrg
}

export const fetchMetaConfigByKey = async (
  key: string,
): Promise<common.MetaConfig> => {
  const res = await utils.get<common.GetMetaConfigRes>(
    `${BASE_URL}/config_by_key/${key}`,
  )

  return res.metaConfig
}
