import * as types from '../types'
import * as utils from '../utils'

export const BASE_URL = '/api'

export type ErrRes = utils.DeepReadonly<{
  readonly err: string
}>

// #region interactionsByOrg
export const ORG_ROUTE = '/org'

export interface GetOrgQuery {
  orgs?: string
  page?: string
  per_page?: string
  sort_mapping?: string
  selected_filters?: string
}

export type GetOrgRes = utils.DeepReadonly<{
  interactionsByOrg: types.Orgs
  page: number
  per_page: number
  total_pages: number
  sort_mapping: types.SortMapping
}>
// #endregion interactionsByOrg

// #region orgsAutocomplete

export const ORGS_AUTOCOMPLETE_ROUTE = '/orgs_autocomplete'

export type GetOrgsAutocompleteQuery = utils.DeepReadonly<{
  q: string
}>

export type GetOrgsAutocompleteRes = utils.DeepReadonly<{
  orgs: readonly string[]
}>

// #endregion orgsAutocomplete
// #region relationshipsForAnOrg
export const RELATIONSHIPS_FOR_AN_ORG_ROUTE = '/relationships_for_an_org'

export type GetRelationshipsForAnOrgParams = utils.DeepReadonly<{
  org_domain: string
}>

export type GetRelationshipsForAnOrgRes = utils.DeepReadonly<{
  relationshipsForAnOrg: readonly types.Relationship[]
}>
// #endregion relationshipsForAnOrg
// #region outreach
export const OUTREACH_FOR_AN_ORG = '/outreach_for_an_org'

export type GetOutreachForAnOrgParams = utils.DeepReadonly<{
  org_domain: string
}>

export type GetOutreachForAnOrgRes = utils.DeepReadonly<{
  outreach_for_an_org: types.Relationships
}>
// #endregion outreach
// #region interactionsWithKeystoneEmployee
export const INTERACTIONS_WITH_KEYSTONE_EMPLOYEE =
  '/interactions_w_keystone_employee'

export type GetInteractionsWithKeystoneEmployeeParams = utils.DeepReadonly<{
  employee_email_address: string
  external_org: string
}>

export type GetInteractionsWithKeystoneEmployeeRes = utils.DeepReadonly<{
  interactions_with_keystone_employee: types.InteractionsWithPersonArr
}>
// #endregion interactionsWithKeystoneEmployee
// #region interactionsWithAPerson
export const INTERACTIONS_WITH_PERSON = '/interactions_w_person'

export type GetInteractionsWithPersonParams = utils.DeepReadonly<{
  email_address: string
}>

export type GetInteractionsWithPersonRes = utils.DeepReadonly<{
  interactionsWithPerson: types.InteractionsWithPerson[]
}>
// #endregion interactionsWithAPerson

// #region emails
export const FIND_EMAILS = '/find_emails'

export type GetFindEmailsParams = utils.DeepReadonly<{
  target_email_address: string
  source_email_address: string
}>

export type GetFindEmailsRes = utils.DeepReadonly<{
  emails: types.Convos
}>

// #endregion emails

// #region exclusions

export type GetExclusionsRes = utils.DeepReadonly<{
  exclusions: types.Exclusions
}>

// #endregion exclusions

// #region excludeEmail

export type PostExcludeEmailBody = utils.DeepReadonly<{
  /**
   * The email address to be excluded.
   */
  emailAddress: string
}>

export type PostExcludeEmailRes = utils.DeepReadonly<{
  ok: true
}>

// #endregion excludeEmail

// #region excludeDomain

export type PostExcludeDomainBody = utils.DeepReadonly<{
  /**
   * The email address to be excluded.
   */
  domain: string
}>

export type PostExcludeDomainRes = utils.DeepReadonly<{
  ok: true
}>

// #endregion excludeDomain

// #region documents
export const ATTACHED_DOCUMENT = '/attached_document'

export type GetAttachedDocumentQuery = utils.DeepReadonly<{
  id: string
  token: string
}>

export type GetAttachedDocumentRes = utils.DeepReadonly<{
  content_bytes: string
}>

export type GetAuth0AccessTokenRes = utils.DeepReadonly<{
  access_token: string
  scope: string
  expires_in: number
  token_type: string
}>

export type GetAuth0AccessTokenErr = utils.DeepReadonly<{
  error: string
  error_description: string
}>

// #endregion documents

// #region assignManager

export const ASSIGN_MANAGER_ROUTE = '/assign_manager'

export type PostAssignManagerBody = utils.DeepReadonly<{
  manager_internal: string
  target_external: string
}>

export type PostAssignManagerRes = utils.DeepReadonly<{
  ok: true
}>

// #endregion assignManager

// #region orgProjects

export const PROJECTS_FOR_AN_ORG = '/projects_for_an_org'

export type GetProjectsForAnOrgParams = utils.DeepReadonly<{
  org_domain: string
}>

export type GetProjectsForAnOrgRes = utils.DeepReadonly<{
  projectsForAnOrg: types.Neo4JProject[]
}>

// #endregion orgProjects

// #region graph db config

export type GetMetaConfigRes = utils.DeepReadonly<{
  metaConfig: types.MetaConfig
}>

// #endregion graph db config
