/* eslint-disable multiline-comment-style */
/* eslint-disable-file spaced-comment */
import * as React from 'react'

import * as common from '../../../common'

import * as Mui from '@mui/material'
import * as mIcon from '@mui/icons-material/index'
import { Link, useLocation, useParams } from 'react-router-dom'

import * as apis from '../../apis'
import * as utils from '../../utils'

export interface DrawerItemsProps {
  readonly loading: boolean
  readonly onOpenRequest: () => void
  readonly open: boolean
}

const DrawerItems = React.memo<DrawerItemsProps>(
  ({ loading, onOpenRequest, open }): React.ReactElement => {
    const isMounted = utils.useIsMounted()
    const location = useLocation()
    const { id } = useParams()

    const [practicesOpen, setPracticesOpen, togglePracticesOpen] =
      utils.useBool(!open)
    const handlePracticesClick = React.useCallback(() => {
      onOpenRequest()
      togglePracticesOpen()
    }, [onOpenRequest, togglePracticesOpen])
    const [viewsOpen, setViewsOpen, toggleViewsOpen] = utils.useBool(!open)
    const handleViewsClick = React.useCallback(() => {
      onOpenRequest()
      toggleViewsOpen()
    }, [onOpenRequest, toggleViewsOpen])
    React.useEffect(() => {
      if (!open) {
        setPracticesOpen(false)
        setViewsOpen(false)
      }
    }, [
      open,
      setPracticesOpen,
      setViewsOpen,
      togglePracticesOpen,
      toggleViewsOpen,
    ])

    // #region practices
    const [
      { err: practicesErr, loading: practicesLoading, practices },
      setPractices,
    ] = React.useState({
      err: '',
      loading: true,
      practices: [] as readonly common.GraphDBView[],
    })
    const fetchPractices = React.useCallback((): void => {
      setPractices((_) => ({
        err: '',
        loading: true,
        practices: [],
      }))
      utils
        .get<{ results: readonly common.GraphDBView[] }>(
          `${apis.BASE_URL}/graph_db_views?type=practice`,
        )
        .then((res) => {
          if (!isMounted()) {
            return
          }
          setPractices((_) => ({
            ..._,
            loading: false,
            practices: res['results'],
          }))
        })
        .catch((e) => {
          setPractices((_) => ({
            ..._,
            err: common.processErr(e),
            loading: false,
          }))
        })
    }, [isMounted])
    React.useEffect((): void => {
      if (!loading) {
        fetchPractices()
      }
    }, [fetchPractices, loading])
    // #endregion practices
    // #region user_views
    const [
      { err: userViewsErr, loading: userViewsLoading, userViews },
      setUserViews,
    ] = React.useState({
      err: '',
      loading: true,
      userViews: [] as readonly common.GraphDBView[],
    })
    const fetchUserViews = React.useCallback((): void => {
      setUserViews((_) => ({
        err: '',
        loading: true,
        userViews: [],
      }))
      utils
        .get<{ results: readonly common.GraphDBView[] }>(
          `${apis.BASE_URL}/graph_db_views?type=user`,
        )
        .then((res) => {
          if (!isMounted()) {
            return
          }
          setUserViews((_) => ({
            ..._,
            loading: false,
            userViews: res['results'],
          }))
        })
        .catch((e) => {
          setUserViews((_) => ({
            ..._,
            err: common.processErr(e),
            loading: false,
          }))
        })
    }, [isMounted])
    React.useEffect((): void => {
      if (!loading) {
        fetchUserViews()
      }
    }, [fetchUserViews, loading])
    // #endregion user_views
    // #region selectedType
    const selectedType = React.useMemo((): 'new' | 'practice' | 'view' => {
      const isPractice = practices.some((p) => p._id === id)
      const isView = userViews.some((v) => v._id === id)
      const truthMap: Record<string, string> = {
        true: 'new',
        [isPractice.toString()]: 'practice',
        [isView.toString()]: 'view',
      }

      return truthMap['true'] as 'new' | 'practice' | 'view'
    }, [id, practices, userViews])
    // #endregion selectedType
    return (
      <>
        <Mui.ListItemButton
          component={Link}
          selected={location.pathname === '/views/new/relationships'}
          to="/views/new/relationships"
        >
          <Mui.ListItemIcon>
            <mIcon.Dashboard />
          </Mui.ListItemIcon>

          <Mui.ListItemText primary="Explorer" />
        </Mui.ListItemButton>

        <Mui.Divider sx={styles.divider} />

        <Mui.ListItemButton
          onClick={handlePracticesClick}
          selected={!open && selectedType === 'practice'}
        >
          {!open && (
            <Mui.ListItemIcon>
              <mIcon.Gavel />
            </Mui.ListItemIcon>
          )}

          <Mui.ListItemText primary="Practices" />
          {open ? (
            practicesOpen ? (
              <mIcon.ExpandLess />
            ) : (
              <mIcon.ExpandMore />
            )
          ) : undefined}
        </Mui.ListItemButton>

        <Mui.Collapse
          in={practicesOpen}
          timeout="auto"
          unmountOnExit
        >
          <Mui.List
            component="div"
            disablePadding
          >
            {((): React.ReactNode => {
              if (practicesErr) {
                return (
                  <>
                    <Mui.Typography
                      align="center"
                      variant="subtitle1"
                    >
                      {practicesErr}
                    </Mui.Typography>

                    <Mui.ListItemButton onClick={fetchPractices}>
                      <Mui.ListItemText
                        primary="Retry"
                        style={
                          open ? styles.retryBtnOpen : styles.retryBtnClosed
                        }
                      />
                    </Mui.ListItemButton>
                  </>
                )
              }
              if (practicesLoading) {
                return placeholder
              }
              return practices.map((p) => (
                <Mui.ListItemButton
                  component={Link}
                  key={p._id}
                  selected={
                    location.pathname === `/views/${p._id}/relationships`
                  }
                  sx={styles.listItemBtn}
                  to={`/views/${p._id}/relationships`}
                >
                  <Mui.ListItemIcon>
                    <mIcon.Gavel />
                  </Mui.ListItemIcon>
                  <Mui.ListItemText
                    primary={p.name}
                    style={open ? styles.listItemTextOpen : undefined}
                  />
                </Mui.ListItemButton>
              ))
            })()}
          </Mui.List>
        </Mui.Collapse>

        <Mui.Divider sx={styles.divider} />

        <Mui.ListItemButton
          onClick={handleViewsClick}
          selected={!open && selectedType === 'view'}
        >
          {!open && (
            <Mui.ListItemIcon>
              <mIcon.Visibility />
            </Mui.ListItemIcon>
          )}
          <Mui.ListItemText primary="My Views" />
          {open ? (
            viewsOpen ? (
              <mIcon.ExpandLess />
            ) : (
              <mIcon.ExpandMore />
            )
          ) : undefined}
        </Mui.ListItemButton>

        <Mui.Collapse
          in={viewsOpen}
          timeout="auto"
          unmountOnExit
        >
          <Mui.List
            component="div"
            disablePadding
          >
            {((): React.ReactNode => {
              if (userViewsErr) {
                return (
                  <>
                    <Mui.Typography
                      align="center"
                      variant="subtitle1"
                    >
                      {userViewsErr}
                    </Mui.Typography>

                    <Mui.ListItemButton onClick={fetchUserViews}>
                      <Mui.ListItemText
                        primary="Retry"
                        style={
                          open ? styles.retryBtnOpen : styles.retryBtnClosed
                        }
                      />
                    </Mui.ListItemButton>
                  </>
                )
              }
              if (userViewsLoading) {
                return placeholder
              }
              return userViews.map((p) => (
                <Mui.ListItemButton
                  component={Link}
                  key={p._id}
                  selected={
                    location.pathname === `/views/${p._id}/relationships`
                  }
                  sx={styles.listItemBtn}
                  to={`/views/${p._id}/relationships`}
                >
                  <Mui.ListItemIcon>
                    <mIcon.Visibility />
                  </Mui.ListItemIcon>

                  <Mui.ListItemText
                    primary={p.name}
                    style={open ? styles.listItemTextOpen : undefined}
                  />
                </Mui.ListItemButton>
              ))
            })()}
          </Mui.List>
        </Mui.Collapse>

        <Mui.Divider sx={styles.divider} />
      </>
    )
  },
)

const styles = {
  centered: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  divider: {
    my: 1,
  },
  link: {
    color: 'inherit',
  },
  listItemBtn: {
    pl: 4,
  },
  listItemTextOpen: {
    whiteSpace: 'pre-wrap',
  },
  retryBtnClosed: {
    textAlign: 'center',
  },
  retryBtnOpen: {
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
} as const

export default DrawerItems

const placeholder = ['l1', 'l2', 'l3'].map((ske) => (
  <Mui.ListItemButton
    key={ske}
    sx={styles.listItemBtn}
  >
    <Mui.Skeleton
      animation="wave"
      variant="text"
    >
      <Mui.ListItemText
        primary={
          'jkasof oiasf ja sifj iasf   aiosfj aoisjf ioasjf ioasjfioajs fa'
        }
      />
    </Mui.Skeleton>
  </Mui.ListItemButton>
))
